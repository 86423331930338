import {
  Image,
  List,
  ListItem,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Text,
  Flex,
  Box,
  VStack,
  Button,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import around_akiba_logo from "./logos/around-akiba.jpg";
import peppermint_logo from "./logos/peppermint.jpg";
import altraverse_logo from "./logos/altraverse.png";
import tokyopop_logo from "./logos/tokyopop.jpg";
import carlsen_logo from "./logos/carlsen.png";
import crunchyroll_logo from "./logos/crunchyroll.png";
import animoon_logo from "./logos/animoon.webp";
import egmont_logo from "./logos/egmont.png";
import dokomi_logo from "./logos/dokomi.png";
import dayjs from "dayjs";

const ScheduleItem = ({ title, time, children, guest, logo }) => (
  <Flex
    p={4}
    bg="whiteAlpha.200"
    rounded="xl"
    direction="row"
    alignItems="stretch"
    justifyContent="stretch"
  >
    <Flex
      fontSize="16px"
      fontWeight="bold"
      alignItems="center"
      justifyContent="center"
      flex="1 0"
      borderRightWidth="6px"
    >
      {time}
    </Flex>
    <VStack p={2} alignItems="flex-start" spacing={2} flex="5 0">
      <Flex
        width="100%"
        alignItems="stretch"
        justifyContent="center"
        direction="row"
      >
        <Heading flexGrow={1} size="md">
          {title}
        </Heading>
        {guest && <Box>{`Mit ${guest}`}</Box>}
      </Flex>
      <Flex
        width="100%"
        alignItems="stretch"
        justifyContent="center"
        direction="row"
      >
        <Box flexGrow={1}>{children}</Box>
        {logo && <Image src={logo} />}
      </Flex>
    </VStack>
  </Flex>
);

const Schedule = () => {
  const defaultIndex =
    {
      "02.10.2021": 1,
      "03.10.2021": 2,
      "04.10.2021": 3,
      "05.10.2021": 4,
      "06.10.2021": 5,
      "07.10.2021": 6,
      "08.10.2021": 7,
      "09.10.2021": 8,
      "10.10.2021": 9,
    }[dayjs().format("DD.MM.YYYY")] || 9;
  return (
    <Tabs defaultIndex={defaultIndex}>
      <TabList>
        <Tab>02.10.2021</Tab>
        <Tab>03.10.2021</Tab>
        <Tab>04.10.2021</Tab>
        <Tab>05.10.2021</Tab>
        <Tab>06.10.2021</Tab>
        <Tab>07.10.2021</Tab>
        <Tab>08.10.2021</Tab>
        <Tab>09.10.2021</Tab>
        <Tab>10.10.2021</Tab>
      </TabList>

      <TabPanels>
        <TabPanel m={0} px={0}>
          <VStack justifyContent="stretch" alignItems="stretch" spacing={6}>
            <Heading bg="whiteAlpha.200" p={2} rounded="lg" size="lg">
              Samstag der 02.10.2021
            </Heading>
            <ScheduleItem time="11:00" title="Eröffnungsstream">
              <Text>
                Wonderful Hobby Life for you Europe 01: was erwartet euch?
              </Text>
              <Text>
                Exklusive Figuren und die ersten Eindrücke des WonHobby Events
                aus Japan
              </Text>
            </ScheduleItem>

            <ScheduleItem
              time="13:00"
              title="Bericht aus Japan"
              guest="Tristan von Around Akiba live aus Japan"
              logo={around_akiba_logo}
            >
              <Text>Bericht vom WonHobby Event aus Japan</Text>
            </ScheduleItem>
            <ScheduleItem time="14:00" title="Figu-Talk Figuren">
              <List>
                <ListItem>News aus Japan</ListItem>
                <ListItem>
                  Fotos der Neuheiten: Nendoroid, Figma, Pop Up Parade und Scale
                  Figuren
                </ListItem>
              </List>
            </ScheduleItem>
            <ScheduleItem
              time="16:00"
              title="Figu-Talk Anime"
              guest="Basti von peppermint"
              logo={peppermint_logo}
            >
              <List>
                <ListItem>Demon Slayer</ListItem>
                <ListItem> das Fate Universum</ListItem>
                <ListItem>Sword Art Online und die Figuren dazu</ListItem>
              </List>
            </ScheduleItem>
            <ScheduleItem time="17:00" title="Figu-Talk Figuren">
              <List>
                <ListItem>News aus Japan</ListItem>
                <ListItem>
                  Fotos der Neuheiten: Nendoroid, Figma, Pop Up Parade und Scale
                  Figuren
                </ListItem>
              </List>
            </ScheduleItem>
          </VStack>
        </TabPanel>
        <TabPanel px={0} m={0}>
          <VStack justifyContent="stretch" alignItems="stretch" spacing={6}>
            <Heading bg="whiteAlpha.200" p={2} rounded="lg" size="lg">
              Sonntag der 03.10.2021
            </Heading>
            <ScheduleItem
              time="11:00"
              title="Wonderful Hobby Life for you Europe 01: News aus Japan"
            >
              Q & A mit Good Smile Company direkt aus Japan
            </ScheduleItem>
            <ScheduleItem time="13:00" title=" 13 Uhr Figu-Talk - Figuren">
              Neue Figuren und News von Fans für Fans zu Genshin Impact
            </ScheduleItem>
            <ScheduleItem
              time="14:00"
              title="Figu-Talk - Manga"
              guest="Johannes von Altraverse"
            >
              über Meine Wiedergeburt als Schleim in einer anderen Welt, Goblin
              Slayer, Kemono Jihen und die Figuren dazu.
              <Image src={altraverse_logo} />
            </ScheduleItem>
            <ScheduleItem time="16:00" title="Gewinnspiel"></ScheduleItem>
            <ScheduleItem
              time="16:30"
              title="Figu-Talk - Spezial"
              guest="ronyah mononoke"
            >
              über Re:Zero, Shield Hero und Jujutsu Kaisen und die Figuren dazu
            </ScheduleItem>
            <ScheduleItem time="18:00" title="Figu-Talk - Figuren">
              Custom-made Nendoroids und Nendoroid modifications, wir zeigen
              unsere und eure Eigenkreationen und kreativen Verschönerungen von
              Nendoroids
            </ScheduleItem>
          </VStack>
        </TabPanel>
        <TabPanel px={0} m={0}>
          <VStack justifyContent="stretch" alignItems="stretch" spacing={6}>
            <Heading bg="whiteAlpha.200" p={2} rounded="lg" size="lg">
              Montag der 04.10.2021
            </Heading>
            <ScheduleItem
              time="17:00"
              title="Figu-Talk - Manga"
              guest="Annika und Steven von TokyoPop"
            >
              Über Shaman King, fate Universum und Sword Art Online
              <Image height="100px" src={tokyopop_logo} />
            </ScheduleItem>
          </VStack>
        </TabPanel>
        <TabPanel px={0} m={0}>
          <VStack justifyContent="stretch" alignItems="stretch" spacing={6}>
            <Heading bg="whiteAlpha.200" p={2} rounded="lg" size="lg">
              Dienstag der 05.10.2021
            </Heading>
            <ScheduleItem
              time="17:00"
              title="Figu-Talk - Manga"
              guest="Anne von Carlsen"
            >
              Über Naruto, My Hero Academia, Attack on Titan und Fairy Tail
              <Image height="100px" src={carlsen_logo} />
            </ScheduleItem>
            <ScheduleItem
              time="19:00"
              title="Figu-Talk - Anime"
              guest="René von Crunchyroll"
            >
              Über Re:Zero, My Hero Academia und JUJUSTU KAISEN
              <Image height="100px" src={crunchyroll_logo} />
            </ScheduleItem>
          </VStack>
        </TabPanel>
        <TabPanel px={0} m={0}>
          <VStack justifyContent="stretch" alignItems="stretch" spacing={6}>
            <Heading bg="whiteAlpha.200" p={2} rounded="lg" size="lg">
              Mittwoch der 06.10.2021
            </Heading>
            <ScheduleItem
              time="17:00"
              title="Figu-Talk - Manga"
              guest="Marco von Egmont Manga"
            >
              <Image height="100px" src={egmont_logo} />
            </ScheduleItem>
            <ScheduleItem
              time="19:00"
              title="Figu-Talk - Convention"
              guest="Andreas von der Dokomi"
            >
              <Image height="100px" src={dokomi_logo} />
            </ScheduleItem>
          </VStack>
        </TabPanel>
        <TabPanel px={0} m={0}>
          <VStack justifyContent="stretch" alignItems="stretch" spacing={6}>
            <Heading bg="whiteAlpha.200" p={2} rounded="lg" size="lg">
              Donnerstag der 07.10.2021
            </Heading>
            <ScheduleItem
              time="17:00"
              title="Figu-Talk"
              guest="VTuber Catboy Fisky"
            >
              Über das Phänomen VTuber
            </ScheduleItem>
          </VStack>
        </TabPanel>
        <TabPanel px={0} m={0}>
          <VStack justifyContent="stretch" alignItems="stretch" spacing={6}>
            <Heading bg="whiteAlpha.200" p={2} rounded="lg" size="lg">
              Freitag der 08.10.2021
            </Heading>
            <ScheduleItem time="17:00" title="Figu-Talk" guest="Erinyeen">
              Über Hatsune Miku
            </ScheduleItem>
            <ScheduleItem
              time="19:00"
              title="Figu-Talk | Anime"
              guest="Fabian von AniMoon"
            >
              Neuigkeiten vom WHL4U
              <Image height="100px" src={animoon_logo} />
            </ScheduleItem>
          </VStack>
        </TabPanel>
        <TabPanel px={0} m={0}>
          <VStack justifyContent="stretch" alignItems="stretch" spacing={6}>
            <Heading bg="whiteAlpha.200" p={2} rounded="lg" size="lg">
              Samstag der 09.10.2021
            </Heading>
            <ScheduleItem
              time="13:00"
              title="Q and A mit GoodSmile Company"
              guest="宇津宮 歐 von GoodSmile Company"
            >
              Neuigkeiten vom WHL4U
            </ScheduleItem>
            <ScheduleItem
              time="19:00"
              title="Figu-Talk | Anime"
              guest="Fabian von AniMoon"
            >
              Neuigkeiten vom WHL4U
              <Image height="100px" src={animoon_logo} />
            </ScheduleItem>
          </VStack>
        </TabPanel>
        <TabPanel px={0} m={0}>
          <VStack justifyContent="stretch" alignItems="stretch" spacing={6}>
            <Heading bg="whiteAlpha.200" p={2} rounded="lg" size="lg">
              Sonntatg der 10.10.2021
            </Heading>
            <ScheduleItem
              time="11:00"
              title="WHL4U Live"
              guest="宇津宮 歐 von GoodSmile Company live vom WHL4U"
            >
              Neuigkeiten vom WHL4U
            </ScheduleItem>
            <ScheduleItem
              time="15:00"
              title="Figu Talk | Games und Gamefiguren"
              guest="Erinyeen"
            >
              Neuigkeiten vom WHL4U
            </ScheduleItem>
            <ScheduleItem
              time="16:00"
              title="Figu-Talk - Spezial"
              guest="ronyah mononoke"
            >
              über Demon Slayer Inuyasha und Tokyo Ghoul
            </ScheduleItem>
            <ScheduleItem
              time="17:00"
              title="Danksagung"
              guest="Jessica von Figuya"
            >
              Wir bedanken uns bei allen Teilnehmern und Partnern
            </ScheduleItem>
          </VStack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default Schedule;
