import {
  DarkMode,
  Text,
  Image,
  Heading,
  Box,
  Flex,
  cookieStorageManager,
  localStorageManager,
  ChakraProvider,
} from "@chakra-ui/react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Menu from "./menu";
import Header from "./Header";
import Routes from "./Routes";
import theme from "./theme";
import figuya_logo from "./logos/figuya.svg";
import piapro_logo from "./logos/piapro-logo.png";

function App() {
  return (
    <ChakraProvider
      cssReset
      colorModeManager={localStorageManager}
      theme={theme}
    >
      <Router>
        <DarkMode>
          <Box position="relative">
            <Header />
            <Flex maxWidth="1200px" margin="auto" direction="column">
              <Menu />
              <Routes />
              <Flex
                direction="row"
                rounded="lg"
                bg="whiteAlpha.200"
                alignItems="center"
                justifyContent="center"
                as="footer"
                flex="1 1 auto"
                m={2}
                p={4}
              >
                <Box flexGrow={1}>
                  <Image src={figuya_logo} />
                </Box>
                <Link to="/impressum">Impressum</Link>
              </Flex>
              <Flex direction="row" py={2} px={4}>
                Art by Rella © Crypton Future Media, INC. www.piapro.net
                <Image ml={3} height="20px" src={piapro_logo} />
              </Flex>
              <Text py={2} px={4}>
                ©2019 暁なつめ・三嶋くろね／KADOKAWA／映画このすば製作委員会
              </Text>
            </Flex>
          </Box>
        </DarkMode>
      </Router>
    </ChakraProvider>
  );
}

export default App;
