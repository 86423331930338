import { Flex, Button, Box, HStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const NavLink = ({ children, to, isDisabled }) => (
  <Button
    colorScheme="blue"
    as={isDisabled ? undefined : Link}
    to={isDisabled ? undefined : to}
    isDisabled={isDisabled}
    size="lg"
    _hover={{ bg: "white" }}
    fontSize="16px"
    fontWeight="bold"
    rounded="md"
  >
    {children}
  </Button>
);

const Menu = () => (
  <Flex
    p={2}
    bg="gray.800"
    zIndex="4"
    height="70px"
    position="sticky"
    top="0"
    direction="row"
    alignItems="center"
    justifyContent="flex-start"
    width="100%"
    overflow="auto"
  >
    <HStack bg="blackAlpha.100" spacing={2}>
      <NavLink to="/">Home</NavLink>
      <NavLink to="/stream">Stream</NavLink>
      <NavLink to="/schedule">Programm</NavLink>
      <NavLink to="/p">Exclusives</NavLink>
    </HStack>
  </Flex>
);

export default Menu;
