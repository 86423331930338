import React from "react";
import whl4u_logo from "./images/logo_wh34-eu.png";
import top from "./images/top.jpeg";
import {
  useBreakpointValue,
  Box,
  Flex,
  Image,
  Heading,
} from "@chakra-ui/react";

const Header = () => {
  const size = useBreakpointValue({ base: "md", sm: "md", md: "lg", lg: "xl" });
  const responsiveTopLogo = useBreakpointValue({
    base: <Image src={whl4u_logo} />,
    sm: <Image src={whl4u_logo} />,
    md: null,
    lg: null,
    xl: null,
    "2xl": null,
  });
  const responsiveLogo = useBreakpointValue({
    base: null,
    sm: null,
    md: <Image src={whl4u_logo} />,
    lg: <Image src={whl4u_logo} />,
    xl: <Image src={whl4u_logo} />,
    "2xl": <Image src={whl4u_logo} />,
  });
  return (
    <Flex
      position="relative"
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      as="header"
      direction="column"
      borderBottomColor="#20BDFF"
      borderBottomWidth="8px"
    >
      {responsiveTopLogo}
      <Image src={top} />
      <Flex
        maxWidth="60%"
        position="absolute"
        bottom="0"
        direction="column"
        alignItems="stretch"
      >
        {responsiveLogo}
        <Flex
          justifyContent="center"
          alignItems="stretch"
          direction={["column", "column", "column", "row"]}
          rounded="md"
          m={4}
          p={4}
          bg="blackAlpha.800"
        >
          <Flex
            direction="column"
            p={4}
            justifyContent="center"
            alignItems="flex-start"
          >
            <Box>Figuya presents</Box>
            <Heading size={size} as="h1">
              Wonderful Hobby Life For You
            </Heading>
            <Box>Europe 01</Box>
          </Flex>
          <Flex
            direction={["column", "row", "row", "column"]}
            p={4}
            borderTopWidth={["4px", "4px", "4px", 0]}
            borderLeftWidth={[0, 0, 0, "4px"]}
            textAlign="center"
            fontSize={["18px", "20px", "24px"]}
          >
            <Box>02.10.2021</Box>
            <Box flexGrow={1}>-</Box>
            <Box>10.10.2021</Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
