import {
  Heading,
  Box,
  Flex,
  Image,
  Button,
  VStack,
  ButtonGroup,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { exclusives as products } from "./data/exclusives";

const Product = () => {
  const { id } = useParams();
  const product = products.find((product) => product.id === parseInt(id));
  return (
    <Box width="100%" height="100vh" top={0} left={0} position="absolute">
      <Flex
        alignItems="stretch"
        justifyContent="center"
        position="fixed"
        zIndex="10"
        as={Link}
        to="/p"
        top={0}
        left={0}
        width="100%"
        height="100%"
        bg="blackAlpha.800"
      >
        <Box
          bg="white"
          overflow="hidden"
          rounded="xl"
          zIndex="11"
          shadow="md"
          onClick={(e) => e.stopPropagation()}
          bg="gray.200"
          as={motion.div}
          height={["90%", "80%"]}
          width={["80%", "50%", "30%"]}
          initial={{ opacity: 0, margin: "auto" }}
          animate={{ opacity: 1 }}
        >
          <Box as="a" href={`https://figuya.com${product.href}`}>
            <Image
              width="100%"
              borderBottomWidth="1px"
              src={`https://figuya.com/${product.profile_picture.profile.url}`}
            />
          </Box>
          <VStack color="black" alignItems="stretch" spacing={4} p={4}>
            <Heading size="sm">{product.title}</Heading>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
};

export default Product;
