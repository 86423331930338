import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const white = "#fff";
// const white = 'rgb(249, 248, 244)';
// const white = '#FFF8DC';
// const white = '#FFF5EE';
// const white = '#EDEAE0'; // alabaster
// const white = '#F0EAD6'; // eggshell
// const white = '#FFDEAD'; // navajo
// const white = "#FFFFF0"; // ivory

// const white = '#FFFAFA';

const black = "#495057";

// This is the default breakpoint
// const breakpoints = createBreakpoints({
//   sm: "20em",
//   md: "50em",
//   lg: "80em",
//   xl: "110em",
// });

const figuyaTheme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        color: mode("gray.800", "white")(props),
        bg: mode("white", "gray.800")(props),
      },
      "*, *::before, &::after": {
        borderColor: mode("blackAlpha.200", "whiteAlpha.300")(props),
      },
    }),
  },
  config: {
    initialColorMode: "dark",
  },
  components: {
    Drawer: {
      baseStyle: (props) => ({
        dialog: {
          bg: mode("white", "gray.800")(props),
        },
      }),
    },
    Input: {
      baseStyle: {
        field: {
          rounded: "sm",
        },
      },
    },
    Button: {
      baseStyle: {
        rounded: "sm",
      },
    },
  },
  fonts: {
    heading: '"PT Sans", sans-serif',
    body: '"PT Sans", system-ui, sans-serif',
    mono: "Menlo, monospace",
  },
  colors: {
    primary: "#f18420",
    white,
    black,
    whiteAlpha: {
      50: "rgba(249, 248, 244, 0.04)",
      100: "rgba(249, 248, 244, 0.06)",
      200: "rgba(249, 248, 244, 0.08)",
      300: "rgba(249, 248, 244, 0.16)",
      400: "rgba(249, 248, 244, 0.24)",
      500: "rgba(249, 248, 244, 0.36)",
      600: "rgba(249, 248, 244, 0.48)",
      700: "rgba(249, 248, 244, 0.64)",
      800: "rgba(249, 248, 244, 0.80)",
      900: "rgba(249, 248, 244, 0.92)",
    },
    blackAlpha: {
      50: "rgba(73,80,87, 0.04)",
      100: "rgba(73,80,87, 0.06)",
      200: "rgba(73,80,87, 0.08)",
      300: "rgba(73,80,87, 0.16)",
      400: "rgba(73,80,87, 0.24)",
      500: "rgba(73,80,87, 0.36)",
      600: "rgba(73,80,87, 0.48)",
      700: "rgba(73,80,87, 0.64)",
      800: "rgba(73,80,87, 0.80)",
      900: "rgba(73,80,87, 0.92)",
    },
    // gray: {
    //   50: '#f8f9fa',
    //   100: '#f1f3f5',
    //   200: '#e9ecef',
    //   300: '#dee2e6',
    //   400: '#ced4da',
    //   500: '#adb5bd',
    //   600: '#868e96',
    //   700: '#495057',
    //   800: '#343a40',
    //   900: '#212529',
    // },
    gray: {
      50: "#fafaf9",
      100: "#f2f1f2",
      200: "#e1dde3",
      300: "#c0b9c2",
      400: "#9a8f9a",
      500: "#7b6c76",
      600: "#635158",
      700: "#4a3c42",
      800: "#32292e",
      900: "#1e191c",
    },
    // gray: {
    //   50: '#FAFAFA',
    //   100: '#F5F5F5',
    //   200: '#EEEEEE',
    //   300: '#E0E0E0',
    //   400: '#BDBDBD',
    //   500: '#9E9E9E',
    //   600: '#757575',
    //   700: '#616161',
    //   800: '#424242',
    //   900: '#212121',
    // },
    otherGray: {
      50: "#F9FAFB",
      100: "#F3F4F6",
      200: "#E5E7EB",
      300: "#D1D5DB",
      400: "#9CA3AF",
      500: "#6B7280",
      600: "#4B5563",
      700: "#374151",
      800: "#1F2937",
      900: "#111827",
    },
    // gray: {
    //   50: '#FAFAF9',
    //   100: '#F5F5F4',
    //   200: '#E7E5E4',
    //   300: '#D6D3D1',
    //   400: '#A8A29E',
    //   500: '#78716C',
    //   600: '#57534E',
    //   700: '#44403C',
    //   800: '#292524',
    //   900: '#1C1917',
    // },
    brown: {
      50: "#efebe9",
      100: "#d7ccc8",
      200: "#bcaaa4",
      300: "#a1887f",
      400: "#8d6e63",
      500: "#795548",
      600: "#6d4c41",
      700: "#5d4037",
      800: "#4e342e",
      900: "#3e2723",
    },
    orange: {
      50: "#fff4e6",
      100: "#ffe8cc",
      200: "#ffd8a8",
      300: "#ffc078",
      400: "#ffa94d",
      500: "#ff922b",
      600: "#fd7e14",
      700: "#f76707",
      800: "#e8590c",
      900: "#d9480f",
    },
    yellow: {
      50: "#fff9db",
      100: "#fff3bf",
      200: "#ffec99",
      300: "#ffe066",
      400: "#ffd43b",
      500: "#fcc419",
      600: "#fab005",
      700: "#f59f00",
      800: "#f08c00",
      900: "#e67700",
    },
    lime: {
      50: "#f4fce3",
      100: "#e9fac8",
      200: "#d8f5a2",
      300: "#c0eb75",
      400: "#a9e34b",
      500: "#94d82d",
      600: "#82c91e",
      700: "#74b816",
      800: "#66a80f",
      900: "#5c940d",
    },
    green: {
      50: "#ebfbee",
      100: "#d3f9d8",
      200: "#b2f2bb",
      300: "#8ce99a",
      400: "#69db7c",
      500: "#51cf66",
      600: "#40c057",
      700: "#37b24d",
      800: "#2f9e44",
      900: "#2b8a3e",
    },
    teal: {
      50: "#e6fcf5",
      100: "#c3fae8",
      200: "#96f2d7",
      300: "#63e6be",
      400: "#38d9a9",
      500: "#20c997",
      600: "#12b886",
      700: "#0ca678",
      800: "#099268",
      900: "#087f5b",
    },
    cyan: {
      50: "#e6fcf5",
      100: "#c3fae8",
      200: "#96f2d7",
      300: "#63e6be",
      400: "#38d9a9",
      500: "#20c997",
      600: "#12b886",
      700: "#0ca678",
      800: "#099268",
      900: "#087f5b",
    },
    blue: {
      50: "#e7f5ff",
      100: "#d0ebff",
      200: "#a5d8ff",
      300: "#74c0fc",
      400: "#4dabf7",
      500: "#339af0",
      600: "#228be6",
      700: "#1c7ed6",
      800: "#1971c2",
      900: "#1864ab",
    },
    indigo: {
      50: "#edf2ff",
      100: "#dbe4ff",
      200: "#bac8ff",
      300: "#91a7ff",
      400: "#748ffc",
      500: "#5c7cfa",
      600: "#4c6ef5",
      700: "#4263eb",
      800: "#3b5bdb",
      900: "#364fc7",
    },
    violet: {
      50: "#f3f0ff",
      100: "#e5dbff",
      200: "#d0bfff",
      300: "#b197fc",
      400: "#9775fa",
      500: "#845ef7",
      600: "#7950f2",
      700: "#7048e8",
      800: "#6741d9",
      900: "#5f3dc4",
    },
    pink: {
      50: "#fff0f6",
      100: "#ffdeeb",
      200: "#fcc2d7",
      300: "#faa2c1",
      400: "#f783ac",
      500: "#f06595",
      600: "#e64980",
      700: "#d6336c",
      800: "#c2255c",
      900: "#a61e4d",
    },
    red: {
      50: "#fff5f5",
      100: "#ffe3e3",
      200: "#ffc9c9",
      300: "#ffa8a8",
      400: "#ff8787",
      500: "#ff6b6b",
      600: "#fa5252",
      700: "#f03e3e",
      800: "#e03131",
      900: "#c92a2a",
    },
  },
});
export default figuyaTheme;
