import React from "react";
import { Flex, Box, HStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import useDimensions from "react-cool-dimensions";

const Stream = () => {
  const { width, observe } = useDimensions();
  React.useEffect(() => {
    if (width > 0) {
      new window.Twitch.Embed("twitch-embed", {
        width: width,
        height: width / 1.8,
        channel: "figuya",
        // Only needed if this page is going to be embedded on other websites
      });
    }
  }, [width]);
  return (
    <Flex m={2}>
      <Box width="100%" ref={observe} id="twitch-embed" />
    </Flex>
  );
};

export default Stream;
