import React from "react";
import ProductList from "./product-list";
import Product from "./product";
import HomePage from "./home-page";
import Stream from "./stream";
import Schedule from "./schedule";
import Impressum from "./Impressum";
import Gallery from "./gallery";

import { Route } from "react-router-dom";

const Routes = () => (
  <>
    <Route exact path="/">
      <HomePage />
    </Route>
    <Route path="/p">
      <ProductList />
    </Route>
    <Route path="/gallery">
      <Gallery />
    </Route>
    <Route path="/p/:id">
      <Product />
    </Route>
    <Route exact path="/schedule">
      <Schedule />
    </Route>
    <Route exact path="/stream">
      <Stream />
    </Route>
    <Route exact path="/impressum">
      <Impressum />
    </Route>
  </>
);

export default Routes;
