import { useState, useRef, useEffect } from "react";
import { Flex, Box, Heading, Badge, HStack, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { exclusives as products } from "./data/exclusives";
import useDimensions from "react-cool-dimensions";

// Hook
function useHover() {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}

const ProductListItem = ({ product }) => {
  const [hoverRef, isHovered] = useHover();
  const { observe, width } = useDimensions();

  return (
    <Flex
      ref={observe}
      direction="column"
      height={width}
      width={["100%", "50%", 1 / 3, "25%"]}
    >
      <Flex
        ref={hoverRef}
        as={Link}
        to={`/p/${product.id}`}
        m={2}
        position="relative"
        direction="column"
        flexGrow={1}
      >
        <Flex
          rounded="xl"
          overflow="hidden"
          flexGrow={1}
          direction="column"
          alignItems="center"
          justifyContent="center"
          bg="whiteAlpha.700"
        >
          <Image
            width="100%"
            src={`https://figuya.com/${product.profile_picture.profile.url}`}
          />
        </Flex>
        <Box
          minHeight="20%"
          position="absolute"
          color="white"
          bg="blackAlpha.900"
          as={motion.div}
          initial={{ opacity: 0.2 }}
          animate={{
            opacity: isHovered ? 1 : 0,
            transition: {
              duration: 1,
            },
          }}
          roundedBottom="xl"
          bottom={0}
          left={0}
          right={0}
          p={2}
        >
          <Heading size="sm">{product.title}</Heading>
        </Box>
      </Flex>
    </Flex>
  );
};

const ProductList = () => (
  <Flex
    direction="row"
    justifyContent="flex-start"
    alignItems="stretch"
    wrap="wrap"
  >
    {products.map((product) => (
      <ProductListItem product={product} key={product.title} />
    ))}
  </Flex>
);

export default ProductList;
