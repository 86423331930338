import React from "react";
import {
  Heading,
  Box,
  Text,
  Link,
  List,
  ListItem,
  Icon,
} from "@chakra-ui/react";

const Impressum = () => (
  <Box p={2} as="article">
    <Heading as="h1">Impressum</Heading>
    <Box mb={4} itemscope="" itemtype="http://data-vocabulary.org/Person">
      <Text>
        Eingetragen beim Amtsgericht Berlin-Charlottenburg
        <br />
        Handelsregisternummer: HRB 183054 B
      </Text>
    </Box>
    <Box mb={4}>
      <Text as="strong">Geschäftsführer</Text>
      <Text itemProp="name">Dr. Jessica Janus, Tobias Praetsch</Text>
    </Box>
    <Box mb={4}>
      <Text d="block" as="strong">
        Adresse
      </Text>
      <Text
        as="address"
        itemProp="address"
        itemscope=""
        itemtype="http://data-vocabulary.org/Address"
      >
        <span itemProp="street-address">Wolfener Str 36</span>
        <br />
        <span>Haus R+S</span>
        <br />
        <span itemProp="postal-code">12681</span>
        <span itemProp="locality">Berlin</span>
        <br />
        <span itemProp="country-name">Deutschland</span>
      </Text>
    </Box>
    <Box mb={4}>
      <Text as="strong">Telefon</Text>
      <Text>+49 (0)30 577 011 989</Text>
    </Box>
    <Box mb={4}>
      <Text as="strong">Fax</Text>
      <Text>+49 (0)30 577 011 987</Text>
    </Box>
    <Box mb={4}>
      <Text as="strong">E-Mail</Text>
      <Text>kontakt@figuya.de</Text>
    </Box>
    <Box mb={4}>
      <Text as="strong">Inhaltlich Verantwortliche gemäß § 55 Abs. 2 RStV</Text>
      <List>
        <ListItem>Figuya GmbH</ListItem>
        <ListItem>Wolfener Str 36</ListItem>
        <ListItem>12681 Berlin</ListItem>
        <ListItem>Deutschland</ListItem>
        <ListItem>
          <Link href="mailto:kontakt@figuya.de"> kontakt@figuya.de</Link>
        </ListItem>
      </List>
    </Box>
  </Box>
);

export default Impressum;
