export const exclusives = [
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "64.9",
    base_price_required: null,
    gross_selling_price: "64.9",
    href: "/de/produkte/nendoroid-1250-hatsune-miku-snow-parade-ver-25967-de",
    id: 25967,
    orderable: true,
    price: "64.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/25967/nendoroid-1250-hatsune-miku-snow-parade-ver20210908-17706-19itz6j.png",
      profile: {
        url: "/uploads/product/profile_picture/25967/profile_nendoroid-1250-hatsune-miku-snow-parade-ver20210908-17706-19itz6j.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/25967/small_profile_nendoroid-1250-hatsune-miku-snow-parade-ver20210908-17706-19itz6j.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/25967/small_profile.nendoroid-1250-hatsune-miku-snow-parade-ver.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/25967/small_profile.nendoroid-1250-hatsune-miku-snow-parade-ver.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/25967/small_profile.nendoroid-1250-hatsune-miku-snow-parade-ver.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/25967/preview_nendoroid-1250-hatsune-miku-snow-parade-ver20210908-17706-19itz6j.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/25967/preview.nendoroid-1250-hatsune-miku-snow-parade-ver.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/25967/preview.nendoroid-1250-hatsune-miku-snow-parade-ver.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/25967/preview.nendoroid-1250-hatsune-miku-snow-parade-ver.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/25967/thumb_nendoroid-1250-hatsune-miku-snow-parade-ver20210908-17706-19itz6j.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/25967/thumb.nendoroid-1250-hatsune-miku-snow-parade-ver.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/25967/thumb.nendoroid-1250-hatsune-miku-snow-parade-ver.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/25967/thumb.nendoroid-1250-hatsune-miku-snow-parade-ver.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/25967/cart_nendoroid-1250-hatsune-miku-snow-parade-ver20210908-17706-19itz6j.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/25967/cart.nendoroid-1250-hatsune-miku-snow-parade-ver.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/25967/cart.nendoroid-1250-hatsune-miku-snow-parade-ver.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/25967/cart.nendoroid-1250-hatsune-miku-snow-parade-ver.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-1250-hatsune-miku-snow-parade-ver-25967-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 1250 Hatsune Miku Snow Parade ver.",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "64.9",
    base_price_required: null,
    gross_selling_price: "64.9",
    href: "/de/produkte/figma-ex-050-persona-5-shujinkou-joker-the-phantom-24342-de",
    id: 24342,
    orderable: true,
    price: "64.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/24342/figma-persona5-hero20210909-17706-107agkj.png",
      profile: {
        url: "/uploads/product/profile_picture/24342/profile_figma-persona5-hero20210909-17706-107agkj.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/24342/small_profile_figma-persona5-hero20210909-17706-107agkj.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/24342/preview_figma-persona5-hero20210909-17706-107agkj.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/24342/thumb_figma-persona5-hero20210909-17706-107agkj.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/24342/cart_figma-persona5-hero20210909-17706-107agkj.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-050-persona-5-shujinkou-joker-the-phantom-24342-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-050 Persona 5 Shujinkou / Joker / The Phantom",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/nendoroid-515-mako-mankanshoku-7272-de",
    id: 7272,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/7272/nendoroid-515-mako-mankanshoku20210908-17706-1430e29.png",
      profile: {
        url: "/uploads/product/profile_picture/7272/profile_nendoroid-515-mako-mankanshoku20210908-17706-1430e29.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/7272/small_profile_nendoroid-515-mako-mankanshoku20210908-17706-1430e29.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/7272/preview_nendoroid-515-mako-mankanshoku20210908-17706-1430e29.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/7272/thumb_nendoroid-515-mako-mankanshoku20210908-17706-1430e29.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/7272/cart_nendoroid-515-mako-mankanshoku20210908-17706-1430e29.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-515-mako-mankanshoku-7272-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 515 Mako Mankanshoku ",
    waitlistable: false,
    weight: 400.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/nendoroid-1331-ryuko-matoi-souvenir-jacket-ver-34234-de",
    id: 34234,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34234/nendoroid-515-mako-mankanshoku20210908-17706-vofriz.png",
      profile: {
        url: "/uploads/product/profile_picture/34234/profile_nendoroid-515-mako-mankanshoku20210908-17706-vofriz.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34234/small_profile_nendoroid-515-mako-mankanshoku20210908-17706-vofriz.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/34234/preview_nendoroid-515-mako-mankanshoku20210908-17706-vofriz.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34234/thumb_nendoroid-515-mako-mankanshoku20210908-17706-vofriz.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/34234/cart_nendoroid-515-mako-mankanshoku20210908-17706-vofriz.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-1331-ryuko-matoi-souvenir-jacket-ver-34234-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 1331 Ryuko Matoi: Souvenir Jacket Ver.",
    waitlistable: false,
    weight: 400.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/nendoroid-1330-luna-lovegood-34240-de",
    id: 34240,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34240/nendoroid-1330-luna-lovegood20210908-17706-e2qf4d.png",
      profile: {
        url: "/uploads/product/profile_picture/34240/profile_nendoroid-1330-luna-lovegood20210908-17706-e2qf4d.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34240/small_profile_nendoroid-1330-luna-lovegood20210908-17706-e2qf4d.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/34240/small_profile.nendoroid-1330-luna-lovegood.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/34240/small_profile.nendoroid-1330-luna-lovegood.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/34240/small_profile.nendoroid-1330-luna-lovegood.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/34240/preview_nendoroid-1330-luna-lovegood20210908-17706-e2qf4d.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/34240/preview.nendoroid-1330-luna-lovegood.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/34240/preview.nendoroid-1330-luna-lovegood.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/34240/preview.nendoroid-1330-luna-lovegood.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34240/thumb_nendoroid-1330-luna-lovegood20210908-17706-e2qf4d.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/34240/thumb.nendoroid-1330-luna-lovegood.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/34240/thumb.nendoroid-1330-luna-lovegood.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/34240/thumb.nendoroid-1330-luna-lovegood.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/34240/cart_nendoroid-1330-luna-lovegood20210908-17706-e2qf4d.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/34240/cart.nendoroid-1330-luna-lovegood.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/34240/cart.nendoroid-1330-luna-lovegood.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/34240/cart.nendoroid-1330-luna-lovegood.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-1330-luna-lovegood-34240-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 1330 Luna Lovegood",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "74.9",
    base_price_required: null,
    gross_selling_price: "74.9",
    href: "/de/produkte/figma-ex-059-fujimaru-ritsuka-24343-de",
    id: 24343,
    orderable: true,
    price: "74.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/24343/figma-ex-059-fujimaru-ritsuka20210908-17748-c8sq7m.png",
      profile: {
        url: "/uploads/product/profile_picture/24343/profile_figma-ex-059-fujimaru-ritsuka20210908-17748-c8sq7m.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/24343/small_profile_figma-ex-059-fujimaru-ritsuka20210908-17748-c8sq7m.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/24343/preview_figma-ex-059-fujimaru-ritsuka20210908-17748-c8sq7m.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/24343/thumb_figma-ex-059-fujimaru-ritsuka20210908-17748-c8sq7m.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/24343/cart_figma-ex-059-fujimaru-ritsuka20210908-17748-c8sq7m.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-059-fujimaru-ritsuka-24343-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-059 Fujimaru Ritsuka",
    waitlistable: false,
    weight: 300.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "69.9",
    base_price_required: null,
    gross_selling_price: "69.9",
    href: "/de/produkte/figma-ex-062-mordred-rider-34232-de",
    id: 34232,
    orderable: true,
    price: "69.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34232/figma-ex-062-mordred-rider20210908-17706-gkgsso.png",
      profile: {
        url: "/uploads/product/profile_picture/34232/profile_figma-ex-062-mordred-rider20210908-17706-gkgsso.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34232/small_profile_figma-ex-062-mordred-rider20210908-17706-gkgsso.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/34232/preview_figma-ex-062-mordred-rider20210908-17706-gkgsso.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34232/thumb_figma-ex-062-mordred-rider20210908-17706-gkgsso.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/34232/cart_figma-ex-062-mordred-rider20210908-17706-gkgsso.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-062-mordred-rider-34232-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-062 Mordred Rider ",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "59.9",
    base_price_required: null,
    gross_selling_price: "59.9",
    href: "/de/produkte/nendoroid-1510-tanjiro-kamado-final-selection-ver-34239-de",
    id: 34239,
    orderable: true,
    price: "59.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34239/nendoroid-1510-tanjiro-kamado-final-selection-ver20210908-17706-mkezse.png",
      profile: {
        url: "/uploads/product/profile_picture/34239/profile_nendoroid-1510-tanjiro-kamado-final-selection-ver20210908-17706-mkezse.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34239/small_profile_nendoroid-1510-tanjiro-kamado-final-selection-ver20210908-17706-mkezse.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/34239/small_profile.nendoroid-1510-tanjiro-kamado-final-selection-ver.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/34239/small_profile.nendoroid-1510-tanjiro-kamado-final-selection-ver.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/34239/small_profile.nendoroid-1510-tanjiro-kamado-final-selection-ver.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/34239/preview_nendoroid-1510-tanjiro-kamado-final-selection-ver20210908-17706-mkezse.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/34239/preview.nendoroid-1510-tanjiro-kamado-final-selection-ver.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/34239/preview.nendoroid-1510-tanjiro-kamado-final-selection-ver.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/34239/preview.nendoroid-1510-tanjiro-kamado-final-selection-ver.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34239/thumb_nendoroid-1510-tanjiro-kamado-final-selection-ver20210908-17706-mkezse.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/34239/thumb.nendoroid-1510-tanjiro-kamado-final-selection-ver.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/34239/thumb.nendoroid-1510-tanjiro-kamado-final-selection-ver.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/34239/thumb.nendoroid-1510-tanjiro-kamado-final-selection-ver.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/34239/cart_nendoroid-1510-tanjiro-kamado-final-selection-ver20210908-17706-mkezse.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/34239/cart.nendoroid-1510-tanjiro-kamado-final-selection-ver.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/34239/cart.nendoroid-1510-tanjiro-kamado-final-selection-ver.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/34239/cart.nendoroid-1510-tanjiro-kamado-final-selection-ver.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-1510-tanjiro-kamado-final-selection-ver-34239-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 1510 Tanjiro Kamado Final Selection Ver.",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "64.9",
    base_price_required: null,
    gross_selling_price: "64.9",
    href: "/de/produkte/figma-ex-063-aqua-swimsuit-ver-34230-de",
    id: 34230,
    orderable: true,
    price: "64.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34230/figma-ex-063-aqua-swimsuit-ver20210908-17706-6k4krw.png",
      profile: {
        url: "/uploads/product/profile_picture/34230/profile_figma-ex-063-aqua-swimsuit-ver20210908-17706-6k4krw.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34230/small_profile_figma-ex-063-aqua-swimsuit-ver20210908-17706-6k4krw.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/34230/preview_figma-ex-063-aqua-swimsuit-ver20210908-17706-6k4krw.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34230/thumb_figma-ex-063-aqua-swimsuit-ver20210908-17706-6k4krw.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/34230/cart_figma-ex-063-aqua-swimsuit-ver20210908-17706-6k4krw.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-063-aqua-swimsuit-ver-34230-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-063 Aqua Swimsuit ver.",
    waitlistable: false,
    weight: 300.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "24.9",
    base_price_required: null,
    gross_selling_price: "24.9",
    href: "/de/produkte/dress-up-china-ver-nendoroid-more-34521-de",
    id: 34521,
    orderable: true,
    price: "24.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34521/profile.png",
      profile: {
        url: "/uploads/product/profile_picture/34521/profile_profile.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34521/small_profile_profile.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/34521/small_profile.dress-up-china-ver-nendoroid-more.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/34521/small_profile.dress-up-china-ver-nendoroid-more.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/34521/small_profile.dress-up-china-ver-nendoroid-more.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/34521/preview_profile.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/34521/preview.dress-up-china-ver-nendoroid-more.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/34521/preview.dress-up-china-ver-nendoroid-more.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/34521/preview.dress-up-china-ver-nendoroid-more.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34521/thumb_profile.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/34521/thumb.dress-up-china-ver-nendoroid-more.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/34521/thumb.dress-up-china-ver-nendoroid-more.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/34521/thumb.dress-up-china-ver-nendoroid-more.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/34521/cart_profile.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/34521/cart.dress-up-china-ver-nendoroid-more.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/34521/cart.dress-up-china-ver-nendoroid-more.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/34521/cart.dress-up-china-ver-nendoroid-more.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: true,
    },
    reservable: false,
    reservation: null,
    slug: "dress-up-china-ver-nendoroid-more-34521-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Dress Up China Ver. - Nendoroid More",
    waitlistable: false,
    weight: 300.0,
  },

  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/nendoroid-1252-sakura-matou-34233-de",
    id: 34233,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34233/nendoroid-1252-sakura-matou20210908-17706-v5fc27.png",
      profile: {
        url: "/uploads/product/profile_picture/34233/profile_nendoroid-1252-sakura-matou20210908-17706-v5fc27.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34233/small_profile_nendoroid-1252-sakura-matou20210908-17706-v5fc27.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/34233/preview_nendoroid-1252-sakura-matou20210908-17706-v5fc27.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34233/thumb_nendoroid-1252-sakura-matou20210908-17706-v5fc27.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/34233/cart_nendoroid-1252-sakura-matou20210908-17706-v5fc27.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2020-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2020",
      outdated: true,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-1252-sakura-matou-34233-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 1252 Sakura Matou",
    waitlistable: false,
    weight: 550.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/nendoroid-1319-snow-miku-2-0-34237-de",
    id: 34237,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34237/nendoroid-1319-snow-miku-2-020210908-17706-1gv1j19.png",
      profile: {
        url: "/uploads/product/profile_picture/34237/profile_nendoroid-1319-snow-miku-2-020210908-17706-1gv1j19.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34237/small_profile_nendoroid-1319-snow-miku-2-020210908-17706-1gv1j19.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/34237/small_profile.nendoroid-1319-snow-miku-2-0.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/34237/small_profile.nendoroid-1319-snow-miku-2-0.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/34237/small_profile.nendoroid-1319-snow-miku-2-0.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/34237/preview_nendoroid-1319-snow-miku-2-020210908-17706-1gv1j19.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/34237/preview.nendoroid-1319-snow-miku-2-0.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/34237/preview.nendoroid-1319-snow-miku-2-0.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/34237/preview.nendoroid-1319-snow-miku-2-0.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34237/thumb_nendoroid-1319-snow-miku-2-020210908-17706-1gv1j19.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/34237/thumb.nendoroid-1319-snow-miku-2-0.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/34237/thumb.nendoroid-1319-snow-miku-2-0.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/34237/thumb.nendoroid-1319-snow-miku-2-0.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/34237/cart_nendoroid-1319-snow-miku-2-020210908-17706-1gv1j19.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/34237/cart.nendoroid-1319-snow-miku-2-0.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/34237/cart.nendoroid-1319-snow-miku-2-0.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/34237/cart.nendoroid-1319-snow-miku-2-0.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-1319-snow-miku-2-0-34237-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 1319 Snow Miku 2.0",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/nendoroid-1332-izuku-midoriya-costume-34238-de",
    id: 34238,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34238/nendoroid-1332-izuku-midoriya-costume20210908-17706-1l5vmmb.png",
      profile: {
        url: "/uploads/product/profile_picture/34238/profile_nendoroid-1332-izuku-midoriya-costume20210908-17706-1l5vmmb.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34238/small_profile_nendoroid-1332-izuku-midoriya-costume20210908-17706-1l5vmmb.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/34238/small_profile.nendoroid-1332-izuku-midoriya-costume.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/34238/small_profile.nendoroid-1332-izuku-midoriya-costume.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/34238/small_profile.nendoroid-1332-izuku-midoriya-costume.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/34238/preview_nendoroid-1332-izuku-midoriya-costume20210908-17706-1l5vmmb.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/34238/preview.nendoroid-1332-izuku-midoriya-costume.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/34238/preview.nendoroid-1332-izuku-midoriya-costume.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/34238/preview.nendoroid-1332-izuku-midoriya-costume.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34238/thumb_nendoroid-1332-izuku-midoriya-costume20210908-17706-1l5vmmb.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/34238/thumb.nendoroid-1332-izuku-midoriya-costume.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/34238/thumb.nendoroid-1332-izuku-midoriya-costume.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/34238/thumb.nendoroid-1332-izuku-midoriya-costume.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/34238/cart_nendoroid-1332-izuku-midoriya-costume20210908-17706-1l5vmmb.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/34238/cart.nendoroid-1332-izuku-midoriya-costume.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/34238/cart.nendoroid-1332-izuku-midoriya-costume.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/34238/cart.nendoroid-1332-izuku-midoriya-costume.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-1332-izuku-midoriya-costume-34238-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 1332 Izuku Midoriya Costume γ",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "12.9",
    base_price_required: null,
    gross_selling_price: "12.9",
    href: "/de/produkte/the-easel-stand-gelb-figurenstaender-fuer-nendoroids-set-mit-3-stueck-34522-de",
    id: 34522,
    orderable: true,
    price: "12.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34522/profile.png",
      profile: {
        url: "/uploads/product/profile_picture/34522/profile_profile.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34522/small_profile_profile.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/34522/preview_profile.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34522/thumb_profile.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/34522/cart_profile.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "the-easel-stand-gelb-figurenstaender-fuer-nendoroids-set-mit-3-stueck-34522-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title:
      "The Easel Stand Gelb - Figurenständer für Nendoroids - Set mit 3 Stück",
    waitlistable: false,
    weight: 120.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "12.9",
    base_price_required: null,
    gross_selling_price: "12.9",
    href: "/de/produkte/the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck-34523-de",
    id: 34523,
    orderable: true,
    price: "12.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34523/profile.png",
      profile: {
        url: "/uploads/product/profile_picture/34523/profile_profile.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34523/small_profile_profile.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/34523/small_profile.the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/34523/small_profile.the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/34523/small_profile.the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/34523/preview_profile.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/34523/preview.the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/34523/preview.the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/34523/preview.the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34523/thumb_profile.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/34523/thumb.the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/34523/thumb.the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/34523/thumb.the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/34523/cart_profile.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/34523/cart.the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/34523/cart.the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/34523/cart.the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "the-easel-stand-rot-figurenstaender-fuer-nendoroids-set-mit-3-stueck-34523-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title:
      "The Easel Stand Rot - Figurenständer für Nendoroids - Set mit 3 Stück",
    waitlistable: false,
    weight: 120.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "12.9",
    base_price_required: null,
    gross_selling_price: "12.9",
    href: "/de/produkte/the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck-34524-de",
    id: 34524,
    orderable: true,
    price: "12.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34524/profile.png",
      profile: {
        url: "/uploads/product/profile_picture/34524/profile_profile.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34524/small_profile_profile.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/34524/small_profile.the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/34524/small_profile.the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/34524/small_profile.the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/34524/preview_profile.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/34524/preview.the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/34524/preview.the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/34524/preview.the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34524/thumb_profile.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/34524/thumb.the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/34524/thumb.the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/34524/thumb.the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/34524/cart_profile.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/34524/cart.the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/34524/cart.the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/34524/cart.the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "the-easel-stand-pink-figurenstaender-fuer-nendoroids-set-mit-3-stueck-34524-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title:
      "The Easel Stand Pink - Figurenständer für Nendoroids - Set mit 3 Stück",
    waitlistable: false,
    weight: 120.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "12.9",
    base_price_required: false,
    gross_selling_price: "12.9",
    href: "/de/produkte/the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck-34525-de",
    id: 34525,
    orderable: true,
    price: "12.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34525/profile.png",
      profile: {
        url: "/uploads/product/profile_picture/34525/profile_profile.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34525/small_profile_profile.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/34525/small_profile.the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/34525/small_profile.the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/34525/small_profile.the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/34525/preview_profile.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/34525/preview.the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/34525/preview.the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/34525/preview.the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34525/thumb_profile.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/34525/thumb.the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/34525/thumb.the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/34525/thumb.the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/34525/cart_profile.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/34525/cart.the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/34525/cart.the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/34525/cart.the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "the-easel-stand-gruen-figurenstaender-fuer-nendoroids-set-mit-3-stueck-34525-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title:
      "The Easel Stand Grün - Figurenständer für Nendoroids - Set mit 3 Stück",
    waitlistable: false,
    weight: 120.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "12.9",
    base_price_required: null,
    gross_selling_price: "12.9",
    href: "/de/produkte/the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck-34526-de",
    id: 34526,
    orderable: true,
    price: "12.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34526/profile.png",
      profile: {
        url: "/uploads/product/profile_picture/34526/profile_profile.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34526/small_profile_profile.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/34526/small_profile.the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/34526/small_profile.the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/34526/small_profile.the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/34526/preview_profile.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/34526/preview.the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/34526/preview.the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/34526/preview.the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34526/thumb_profile.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/34526/thumb.the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/34526/thumb.the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/34526/thumb.the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/34526/cart_profile.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/34526/cart.the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/34526/cart.the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/34526/cart.the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "the-easel-stand-blau-figurenstaender-fuer-nendoroids-set-mit-3-stueck-34526-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title:
      "The Easel Stand Blau - Figurenständer für Nendoroids - Set mit 3 Stück",
    waitlistable: false,
    weight: 120.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "12.9",
    base_price_required: null,
    gross_selling_price: "12.9",
    href: "/de/produkte/the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck-34527-de",
    id: 34527,
    orderable: true,
    price: "12.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34527/profile.png",
      profile: {
        url: "/uploads/product/profile_picture/34527/profile_profile.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34527/small_profile_profile.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/34527/small_profile.the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/34527/small_profile.the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/34527/small_profile.the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/34527/preview_profile.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/34527/preview.the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/34527/preview.the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/34527/preview.the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34527/thumb_profile.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/34527/thumb.the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/34527/thumb.the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/34527/thumb.the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/34527/cart_profile.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/34527/cart.the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/34527/cart.the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/34527/cart.the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "the-easel-stand-schwarz-figurenstaender-fuer-nendoroids-set-mit-3-stueck-34527-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title:
      "The Easel Stand Schwarz - Figurenständer für Nendoroids - Set mit 3 Stück",
    waitlistable: false,
    weight: 120.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "59.9",
    base_price_required: null,
    gross_selling_price: "59.9",
    href: "/de/produkte/nendoroid-1000-snow-miku-2019-snow-princess-14851-de",
    id: 14851,
    orderable: true,
    price: "59.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/14851/20190318-19811-1d86ndv.jpeg",
      profile: {
        url: "/uploads/product/profile_picture/14851/profile_20190318-19811-1d86ndv.jpeg",
        type: "image/jpeg",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/14851/small_profile_20190318-19811-1d86ndv.jpeg",
        type: "image/jpeg",
      },
      preview: {
        url: "/uploads/product/profile_picture/14851/preview_20190318-19811-1d86ndv.jpeg",
        type: "image/jpeg",
      },
      thumb: {
        url: "/uploads/product/profile_picture/14851/thumb_20190318-19811-1d86ndv.jpeg",
        type: "image/jpeg",
      },
      cart: {
        url: "/uploads/product/profile_picture/14851/cart_20190318-19811-1d86ndv.jpeg",
        type: "image/jpeg",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-1000-snow-miku-2019-snow-princess-14851-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 1000 Snow Miku 2019 Snow Princess",
    waitlistable: false,
    weight: 600.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "59.9",
    base_price_required: null,
    gross_selling_price: "59.9",
    href: "/de/produkte/nendoroid-850-hatsune-miku-crane-priestess-ver-snow-miku-2018-10974-de",
    id: 10974,
    orderable: true,
    price: "59.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/10974/nendoroid-850-snow-miku-2018-crane-priestess-exklusiv20180219-7866-cl4utr",
      profile: {
        url: "/uploads/product/profile_picture/10974/profile_nendoroid-850-snow-miku-2018-crane-priestess-exklusiv20180219-7866-cl4utr",
        type: "",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/10974/small_profile_nendoroid-850-snow-miku-2018-crane-priestess-exklusiv20180219-7866-cl4utr",
        type: "",
      },
      preview: {
        url: "/uploads/product/profile_picture/10974/preview_nendoroid-850-snow-miku-2018-crane-priestess-exklusiv20180219-7866-cl4utr",
        type: "",
      },
      thumb: {
        url: "/uploads/product/profile_picture/10974/thumb_nendoroid-850-snow-miku-2018-crane-priestess-exklusiv20180219-7866-cl4utr",
        type: "",
      },
      cart: {
        url: "/uploads/product/profile_picture/10974/cart_nendoroid-850-snow-miku-2018-crane-priestess-exklusiv20180219-7866-cl4utr",
        type: "",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-850-hatsune-miku-crane-priestess-ver-snow-miku-2018-10974-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 850 Hatsune Miku Crane Priestess Ver. - Snow Miku 2018",
    waitlistable: false,
    weight: 600.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "89.9",
    base_price_required: null,
    gross_selling_price: "89.9",
    href: "/de/produkte/figma-ex-055-hatsune-miku-magical-snow-ver-24349-de",
    id: 24349,
    orderable: true,
    price: "89.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/24349/figma-ex-055-hatsune-miku-magical-snow-ver20210908-17706-ernf58.png",
      profile: {
        url: "/uploads/product/profile_picture/24349/profile_figma-ex-055-hatsune-miku-magical-snow-ver20210908-17706-ernf58.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/24349/small_profile_figma-ex-055-hatsune-miku-magical-snow-ver20210908-17706-ernf58.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/24349/small_profile.figma-ex-055-hatsune-miku-magical-snow-ver.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/24349/small_profile.figma-ex-055-hatsune-miku-magical-snow-ver.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/24349/small_profile.figma-ex-055-hatsune-miku-magical-snow-ver.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/24349/preview_figma-ex-055-hatsune-miku-magical-snow-ver20210908-17706-ernf58.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/24349/preview.figma-ex-055-hatsune-miku-magical-snow-ver.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/24349/preview.figma-ex-055-hatsune-miku-magical-snow-ver.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/24349/preview.figma-ex-055-hatsune-miku-magical-snow-ver.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/24349/thumb_figma-ex-055-hatsune-miku-magical-snow-ver20210908-17706-ernf58.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/24349/thumb.figma-ex-055-hatsune-miku-magical-snow-ver.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/24349/thumb.figma-ex-055-hatsune-miku-magical-snow-ver.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/24349/thumb.figma-ex-055-hatsune-miku-magical-snow-ver.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/24349/cart_figma-ex-055-hatsune-miku-magical-snow-ver20210908-17706-ernf58.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/24349/cart.figma-ex-055-hatsune-miku-magical-snow-ver.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/24349/cart.figma-ex-055-hatsune-miku-magical-snow-ver.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/24349/cart.figma-ex-055-hatsune-miku-magical-snow-ver.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-055-hatsune-miku-magical-snow-ver-24349-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-055 Hatsune Miku Magical Snow ver.",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "59.9",
    base_price_required: null,
    gross_selling_price: "59.9",
    href: "/de/produkte/figma-ex-054-hatsune-miku-snow-princess-ver-24346-de",
    id: 24346,
    orderable: true,
    price: "59.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/24346/figma-ex-054-hatsune-miku-snow-princess-ver20210908-17829-1rtb4d1.png",
      profile: {
        url: "/uploads/product/profile_picture/24346/profile_figma-ex-054-hatsune-miku-snow-princess-ver20210908-17829-1rtb4d1.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/24346/small_profile_figma-ex-054-hatsune-miku-snow-princess-ver20210908-17829-1rtb4d1.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/24346/preview_figma-ex-054-hatsune-miku-snow-princess-ver20210908-17829-1rtb4d1.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/24346/thumb_figma-ex-054-hatsune-miku-snow-princess-ver20210908-17829-1rtb4d1.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/24346/cart_figma-ex-054-hatsune-miku-snow-princess-ver20210908-17829-1rtb4d1.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-054-hatsune-miku-snow-princess-ver-24346-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-054 Hatsune Miku Snow Princess Ver. ",
    waitlistable: false,
    weight: 300.0,
  },
  {
    availability: {
      description: "Wenige verfügbar",
      out: false,
      low: true,
      color: "orange",
    },
    base_price: "79.9",
    base_price_required: null,
    gross_selling_price: "79.9",
    href: "/de/produkte/figma-ex-039-hatsune-miku-fluffy-coat-ver-34364-de",
    id: 34364,
    orderable: true,
    price: "79.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34364/figma-ex-039-hatsune-miku-snow-fluffy-coat-ver20210913-13394-icvrv7.png",
      profile: {
        url: "/uploads/product/profile_picture/34364/profile_figma-ex-039-hatsune-miku-snow-fluffy-coat-ver20210913-13394-icvrv7.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34364/small_profile_figma-ex-039-hatsune-miku-snow-fluffy-coat-ver20210913-13394-icvrv7.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/34364/preview_figma-ex-039-hatsune-miku-snow-fluffy-coat-ver20210913-13394-icvrv7.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34364/thumb_figma-ex-039-hatsune-miku-snow-fluffy-coat-ver20210913-13394-icvrv7.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/34364/cart_figma-ex-039-hatsune-miku-snow-fluffy-coat-ver20210913-13394-icvrv7.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-039-hatsune-miku-fluffy-coat-ver-34364-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-039 Hatsune Miku Fluffy Coat ver.",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "64.9",
    base_price_required: null,
    gross_selling_price: "64.9",
    href: "/de/produkte/figma-ex-030-hatsune-miku-snow-owl-ver-6721-de",
    id: 6721,
    orderable: true,
    price: "64.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/6721/figma-ex-030-hatsune-miku-snow-owl-ver20210914-32673-bizu7b.png",
      profile: {
        url: "/uploads/product/profile_picture/6721/profile_figma-ex-030-hatsune-miku-snow-owl-ver20210914-32673-bizu7b.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/6721/small_profile_figma-ex-030-hatsune-miku-snow-owl-ver20210914-32673-bizu7b.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/6721/preview_figma-ex-030-hatsune-miku-snow-owl-ver20210914-32673-bizu7b.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/6721/thumb_figma-ex-030-hatsune-miku-snow-owl-ver20210914-32673-bizu7b.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/6721/cart_figma-ex-030-hatsune-miku-snow-owl-ver20210914-32673-bizu7b.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-030-hatsune-miku-snow-owl-ver-6721-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-030 Hatsune Miku Snow Owl Ver.",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "54.9",
    base_price_required: null,
    gross_selling_price: "54.9",
    href: "/de/produkte/figma-ex-024-hatsune-miku-snow-bell-ver-5169-de",
    id: 5169,
    orderable: true,
    price: "54.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/5169/figma-ex-024-miku-hatsune-snow-bell-ver20210914-32731-1wemqae.png",
      profile: {
        url: "/uploads/product/profile_picture/5169/profile_figma-ex-024-miku-hatsune-snow-bell-ver20210914-32731-1wemqae.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/5169/small_profile_figma-ex-024-miku-hatsune-snow-bell-ver20210914-32731-1wemqae.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/5169/preview_figma-ex-024-miku-hatsune-snow-bell-ver20210914-32731-1wemqae.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/5169/thumb_figma-ex-024-miku-hatsune-snow-bell-ver20210914-32731-1wemqae.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/5169/cart_figma-ex-024-miku-hatsune-snow-bell-ver20210914-32731-1wemqae.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-024-hatsune-miku-snow-bell-ver-5169-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-024 Hatsune Miku Snow Bell Ver.",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "64.9",
    base_price_required: null,
    gross_selling_price: "64.9",
    href: "/de/produkte/nendoroid-942-rem-und-ram-childhood-version-16788-de",
    id: 16788,
    orderable: true,
    price: "64.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/16788/nendoroid-942-rem-und-ram-childhood-version20210908-17706-4edbm4.png",
      profile: {
        url: "/uploads/product/profile_picture/16788/profile_nendoroid-942-rem-und-ram-childhood-version20210908-17706-4edbm4.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/16788/small_profile_nendoroid-942-rem-und-ram-childhood-version20210908-17706-4edbm4.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/16788/preview_nendoroid-942-rem-und-ram-childhood-version20210908-17706-4edbm4.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/16788/thumb_nendoroid-942-rem-und-ram-childhood-version20210908-17706-4edbm4.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/16788/cart_nendoroid-942-rem-und-ram-childhood-version20210908-17706-4edbm4.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-942-rem-und-ram-childhood-version-16788-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 942 Rem und Ram Childhood Version ",
    waitlistable: false,
    weight: 400.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "69.9",
    base_price_required: null,
    gross_selling_price: "69.9",
    href: "/de/produkte/figma-ex-057-saber-alter-rider-ver-26744-de",
    id: 26744,
    orderable: true,
    price: "69.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/26744/figma-ex-057-saber-alter-rider-ver20210909-13334-1sqacdx.png",
      profile: {
        url: "/uploads/product/profile_picture/26744/profile_figma-ex-057-saber-alter-rider-ver20210909-13334-1sqacdx.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/26744/small_profile_figma-ex-057-saber-alter-rider-ver20210909-13334-1sqacdx.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/26744/preview_figma-ex-057-saber-alter-rider-ver20210909-13334-1sqacdx.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/26744/thumb_figma-ex-057-saber-alter-rider-ver20210909-13334-1sqacdx.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/26744/cart_figma-ex-057-saber-alter-rider-ver20210909-13334-1sqacdx.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-057-saber-alter-rider-ver-26744-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "figma EX-057 Saber Alter Rider Ver.",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/nendoroid-1150-rider-altria-pendragon-26745-de",
    id: 26745,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/26745/rider-altria-pendragon-nendoroid20210908-17748-15ny0o.png",
      profile: {
        url: "/uploads/product/profile_picture/26745/profile_rider-altria-pendragon-nendoroid20210908-17748-15ny0o.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/26745/small_profile_rider-altria-pendragon-nendoroid20210908-17748-15ny0o.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/26745/small_profile.nendoroid-1150-rider-altria-pendragon.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/26745/small_profile.nendoroid-1150-rider-altria-pendragon.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/26745/small_profile.nendoroid-1150-rider-altria-pendragon.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/26745/preview_rider-altria-pendragon-nendoroid20210908-17748-15ny0o.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/26745/preview.nendoroid-1150-rider-altria-pendragon.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/26745/preview.nendoroid-1150-rider-altria-pendragon.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/26745/preview.nendoroid-1150-rider-altria-pendragon.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/26745/thumb_rider-altria-pendragon-nendoroid20210908-17748-15ny0o.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/26745/thumb.nendoroid-1150-rider-altria-pendragon.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/26745/thumb.nendoroid-1150-rider-altria-pendragon.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/26745/thumb.nendoroid-1150-rider-altria-pendragon.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/26745/cart_rider-altria-pendragon-nendoroid20210908-17748-15ny0o.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/26745/cart.nendoroid-1150-rider-altria-pendragon.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/26745/cart.nendoroid-1150-rider-altria-pendragon.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/26745/cart.nendoroid-1150-rider-altria-pendragon.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-1150-rider-altria-pendragon-26745-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 1150 Rider/Altria Pendragon",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/nendoroid-1081-saitama-oppai-hoodie-ver-25974-de",
    id: 25974,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/25974/nendoroid-1081-saitama-oppai-hoodie-ver20210908-17706-1c49tis.png",
      profile: {
        url: "/uploads/product/profile_picture/25974/profile_nendoroid-1081-saitama-oppai-hoodie-ver20210908-17706-1c49tis.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/25974/small_profile_nendoroid-1081-saitama-oppai-hoodie-ver20210908-17706-1c49tis.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/25974/small_profile.nendoroid-1081-saitama-oppai-hoodie-ver.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/25974/small_profile.nendoroid-1081-saitama-oppai-hoodie-ver.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/25974/small_profile.nendoroid-1081-saitama-oppai-hoodie-ver.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/25974/preview_nendoroid-1081-saitama-oppai-hoodie-ver20210908-17706-1c49tis.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/25974/preview.nendoroid-1081-saitama-oppai-hoodie-ver.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/25974/preview.nendoroid-1081-saitama-oppai-hoodie-ver.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/25974/preview.nendoroid-1081-saitama-oppai-hoodie-ver.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/25974/thumb_nendoroid-1081-saitama-oppai-hoodie-ver20210908-17706-1c49tis.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/25974/thumb.nendoroid-1081-saitama-oppai-hoodie-ver.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/25974/thumb.nendoroid-1081-saitama-oppai-hoodie-ver.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/25974/thumb.nendoroid-1081-saitama-oppai-hoodie-ver.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/25974/cart_nendoroid-1081-saitama-oppai-hoodie-ver20210908-17706-1c49tis.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/25974/cart.nendoroid-1081-saitama-oppai-hoodie-ver.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/25974/cart.nendoroid-1081-saitama-oppai-hoodie-ver.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/25974/cart.nendoroid-1081-saitama-oppai-hoodie-ver.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-1081-saitama-oppai-hoodie-ver-25974-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 1081 Saitama Oppai Hoodie Ver.",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver-25969-de",
    id: 25969,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/25969/nendroid-105120210908-17706-k4gqf0.png",
      profile: {
        url: "/uploads/product/profile_picture/25969/profile_nendroid-105120210908-17706-k4gqf0.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/25969/small_profile_nendroid-105120210908-17706-k4gqf0.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/25969/small_profile.nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/25969/small_profile.nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/25969/small_profile.nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/25969/preview_nendroid-105120210908-17706-k4gqf0.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/25969/preview.nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/25969/preview.nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/25969/preview.nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/25969/thumb_nendroid-105120210908-17706-k4gqf0.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/25969/thumb.nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/25969/thumb.nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/25969/thumb.nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/25969/cart_nendroid-105120210908-17706-k4gqf0.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/25969/cart.nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/25969/cart.nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/25969/cart.nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-1051-arthur-pendragon-costume-dress-white-rose-ver-25969-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 1051 Arthur Pendragon Costume Dress White Rose Ver.",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/nendoroid-941-shielder-mash-kyrielight-casual-ver-25966-de",
    id: 25966,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/25966/nendoroid-schelder-mash-kryielight20210909-17706-moxkbr.png",
      profile: {
        url: "/uploads/product/profile_picture/25966/profile_nendoroid-schelder-mash-kryielight20210909-17706-moxkbr.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/25966/small_profile_nendoroid-schelder-mash-kryielight20210909-17706-moxkbr.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/25966/small_profile.nendoroid-941-shielder-mash-kyrielight-casual-ver.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/25966/small_profile.nendoroid-941-shielder-mash-kyrielight-casual-ver.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/25966/small_profile.nendoroid-941-shielder-mash-kyrielight-casual-ver.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/25966/preview_nendoroid-schelder-mash-kryielight20210909-17706-moxkbr.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/25966/preview.nendoroid-941-shielder-mash-kyrielight-casual-ver.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/25966/preview.nendoroid-941-shielder-mash-kyrielight-casual-ver.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/25966/preview.nendoroid-941-shielder-mash-kyrielight-casual-ver.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/25966/thumb_nendoroid-schelder-mash-kryielight20210909-17706-moxkbr.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/25966/thumb.nendoroid-941-shielder-mash-kyrielight-casual-ver.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/25966/thumb.nendoroid-941-shielder-mash-kyrielight-casual-ver.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/25966/thumb.nendoroid-941-shielder-mash-kyrielight-casual-ver.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/25966/cart_nendoroid-schelder-mash-kryielight20210909-17706-moxkbr.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/25966/cart.nendoroid-941-shielder-mash-kyrielight-casual-ver.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/25966/cart.nendoroid-941-shielder-mash-kyrielight-casual-ver.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/25966/cart.nendoroid-941-shielder-mash-kyrielight-casual-ver.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-941-shielder-mash-kyrielight-casual-ver-25966-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 941 Shielder/Mash Kyrielight Casual ver.",
    waitlistable: false,
    weight: 300.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "59.9",
    base_price_required: null,
    gross_selling_price: "59.9",
    href: "/de/produkte/figma-ex-051-shielder-mash-kyrielight-casual-ver-24345-de",
    id: 24345,
    orderable: true,
    price: "59.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/24345/figma-shielder-mash-kyrielight-casual-ver20210909-17706-vtaag1.png",
      profile: {
        url: "/uploads/product/profile_picture/24345/profile_figma-shielder-mash-kyrielight-casual-ver20210909-17706-vtaag1.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/24345/small_profile_figma-shielder-mash-kyrielight-casual-ver20210909-17706-vtaag1.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/24345/small_profile.figma-ex-051-shielder-mash-kyrielight-casual-ver.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/24345/small_profile.figma-ex-051-shielder-mash-kyrielight-casual-ver.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/24345/small_profile.figma-ex-051-shielder-mash-kyrielight-casual-ver.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/24345/preview_figma-shielder-mash-kyrielight-casual-ver20210909-17706-vtaag1.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/24345/preview.figma-ex-051-shielder-mash-kyrielight-casual-ver.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/24345/preview.figma-ex-051-shielder-mash-kyrielight-casual-ver.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/24345/preview.figma-ex-051-shielder-mash-kyrielight-casual-ver.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/24345/thumb_figma-shielder-mash-kyrielight-casual-ver20210909-17706-vtaag1.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/24345/thumb.figma-ex-051-shielder-mash-kyrielight-casual-ver.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/24345/thumb.figma-ex-051-shielder-mash-kyrielight-casual-ver.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/24345/thumb.figma-ex-051-shielder-mash-kyrielight-casual-ver.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/24345/cart_figma-shielder-mash-kyrielight-casual-ver20210909-17706-vtaag1.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/24345/cart.figma-ex-051-shielder-mash-kyrielight-casual-ver.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/24345/cart.figma-ex-051-shielder-mash-kyrielight-casual-ver.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/24345/cart.figma-ex-051-shielder-mash-kyrielight-casual-ver.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-051-shielder-mash-kyrielight-casual-ver-24345-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-051 Shielder/Mash Kyrielight Casual ver. ",
    waitlistable: false,
    weight: 300.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/mikazuki-munechika-nendoroid-co-de-awakened-ver-34406-de",
    id: 34406,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/34406/mikazuki-munechika-nendoroid-co-de-awakened-ver20210914-32654-1fcr4ga.png",
      profile: {
        url: "/uploads/product/profile_picture/34406/profile_mikazuki-munechika-nendoroid-co-de-awakened-ver20210914-32654-1fcr4ga.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/34406/small_profile_mikazuki-munechika-nendoroid-co-de-awakened-ver20210914-32654-1fcr4ga.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/34406/small_profile.mikazuki-munechika-nendoroid-co-de-awakened-ver.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/34406/small_profile.mikazuki-munechika-nendoroid-co-de-awakened-ver.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/34406/small_profile.mikazuki-munechika-nendoroid-co-de-awakened-ver.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/34406/preview_mikazuki-munechika-nendoroid-co-de-awakened-ver20210914-32654-1fcr4ga.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/34406/preview.mikazuki-munechika-nendoroid-co-de-awakened-ver.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/34406/preview.mikazuki-munechika-nendoroid-co-de-awakened-ver.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/34406/preview.mikazuki-munechika-nendoroid-co-de-awakened-ver.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/34406/thumb_mikazuki-munechika-nendoroid-co-de-awakened-ver20210914-32654-1fcr4ga.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/34406/thumb.mikazuki-munechika-nendoroid-co-de-awakened-ver.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/34406/thumb.mikazuki-munechika-nendoroid-co-de-awakened-ver.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/34406/thumb.mikazuki-munechika-nendoroid-co-de-awakened-ver.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/34406/cart_mikazuki-munechika-nendoroid-co-de-awakened-ver20210914-32654-1fcr4ga.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/34406/cart.mikazuki-munechika-nendoroid-co-de-awakened-ver.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/34406/cart.mikazuki-munechika-nendoroid-co-de-awakened-ver.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/34406/cart.mikazuki-munechika-nendoroid-co-de-awakened-ver.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "mikazuki-munechika-nendoroid-co-de-awakened-ver-34406-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Mikazuki Munechika - Nendoroid Co-de - Awakened Ver.",
    waitlistable: false,
    weight: 400.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "69.9",
    base_price_required: null,
    gross_selling_price: "69.9",
    href: "/de/produkte/figma-ex-056-megumin-swimsuit-ver-24341-de",
    id: 24341,
    orderable: true,
    price: "69.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/24341/figma-ex-056-megumin-swimsuit-ver20210908-17706-1i2wjkn.png",
      profile: {
        url: "/uploads/product/profile_picture/24341/profile_figma-ex-056-megumin-swimsuit-ver20210908-17706-1i2wjkn.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/24341/small_profile_figma-ex-056-megumin-swimsuit-ver20210908-17706-1i2wjkn.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/24341/preview_figma-ex-056-megumin-swimsuit-ver20210908-17706-1i2wjkn.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/24341/thumb_figma-ex-056-megumin-swimsuit-ver20210908-17706-1i2wjkn.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/24341/cart_figma-ex-056-megumin-swimsuit-ver20210908-17706-1i2wjkn.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-056-megumin-swimsuit-ver-24341-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-056 Megumin Swimsuit ver.",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "54.9",
    base_price_required: null,
    gross_selling_price: "54.9",
    href: "/de/produkte/nendoroid-886-jiraiya-gamabunta-set-25961-de",
    id: 25961,
    orderable: true,
    price: "54.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/25961/nendoroid-jiraiya20210907-17706-1cla1j1.png",
      profile: {
        url: "/uploads/product/profile_picture/25961/profile_nendoroid-jiraiya20210907-17706-1cla1j1.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/25961/small_profile_nendoroid-jiraiya20210907-17706-1cla1j1.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/25961/preview_nendoroid-jiraiya20210907-17706-1cla1j1.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/25961/thumb_nendoroid-jiraiya20210907-17706-1cla1j1.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/25961/cart_nendoroid-jiraiya20210907-17706-1cla1j1.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-886-jiraiya-gamabunta-set-25961-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 886 Jiraiya & Gamabunta Set",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/nendoroid-930-hatsune-miku-magical-mirai-2017-ver-26002-de",
    id: 26002,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/26002/nendoroid-930-hatsune-miku-magical-mirai-2017-ver20210909-17706-12ey4h3.png",
      profile: {
        url: "/uploads/product/profile_picture/26002/profile_nendoroid-930-hatsune-miku-magical-mirai-2017-ver20210909-17706-12ey4h3.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/26002/small_profile_nendoroid-930-hatsune-miku-magical-mirai-2017-ver20210909-17706-12ey4h3.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/26002/small_profile.nendoroid-930-hatsune-miku-magical-mirai-2017-ver.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/26002/small_profile.nendoroid-930-hatsune-miku-magical-mirai-2017-ver.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/26002/small_profile.nendoroid-930-hatsune-miku-magical-mirai-2017-ver.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/26002/preview_nendoroid-930-hatsune-miku-magical-mirai-2017-ver20210909-17706-12ey4h3.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/26002/preview.nendoroid-930-hatsune-miku-magical-mirai-2017-ver.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/26002/preview.nendoroid-930-hatsune-miku-magical-mirai-2017-ver.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/26002/preview.nendoroid-930-hatsune-miku-magical-mirai-2017-ver.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/26002/thumb_nendoroid-930-hatsune-miku-magical-mirai-2017-ver20210909-17706-12ey4h3.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/26002/thumb.nendoroid-930-hatsune-miku-magical-mirai-2017-ver.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/26002/thumb.nendoroid-930-hatsune-miku-magical-mirai-2017-ver.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/26002/thumb.nendoroid-930-hatsune-miku-magical-mirai-2017-ver.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/26002/cart_nendoroid-930-hatsune-miku-magical-mirai-2017-ver20210909-17706-12ey4h3.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/26002/cart.nendoroid-930-hatsune-miku-magical-mirai-2017-ver.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/26002/cart.nendoroid-930-hatsune-miku-magical-mirai-2017-ver.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/26002/cart.nendoroid-930-hatsune-miku-magical-mirai-2017-ver.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-930-hatsune-miku-magical-mirai-2017-ver-26002-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 930 Hatsune Miku Magical Mirai 2017 ver. ",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "54.9",
    base_price_required: null,
    gross_selling_price: "54.9",
    href: "/de/produkte/nendoroid-785-hatsune-miku-magical-mirai-5th-anniversary-ver-10971-de",
    id: 10971,
    orderable: true,
    price: "54.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/10971/nendoroid-785-nendoroid-hatsune-miku-magical-mirai-5th-anniversary-exklusiv20210909-17706-13r3s4j.png",
      profile: {
        url: "/uploads/product/profile_picture/10971/profile_nendoroid-785-nendoroid-hatsune-miku-magical-mirai-5th-anniversary-exklusiv20210909-17706-13r3s4j.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/10971/small_profile_nendoroid-785-nendoroid-hatsune-miku-magical-mirai-5th-anniversary-exklusiv20210909-17706-13r3s4j.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/10971/preview_nendoroid-785-nendoroid-hatsune-miku-magical-mirai-5th-anniversary-exklusiv20210909-17706-13r3s4j.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/10971/thumb_nendoroid-785-nendoroid-hatsune-miku-magical-mirai-5th-anniversary-exklusiv20210909-17706-13r3s4j.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/10971/cart_nendoroid-785-nendoroid-hatsune-miku-magical-mirai-5th-anniversary-exklusiv20210909-17706-13r3s4j.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-785-hatsune-miku-magical-mirai-5th-anniversary-ver-10971-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 785 Hatsune Miku Magical Mirai 5th Anniversary ver. ",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "64.9",
    base_price_required: null,
    gross_selling_price: "64.9",
    href: "/de/produkte/nendoroid-1151-hatsune-miku-magical-mirai-2018-ver-19012-de",
    id: 19012,
    orderable: true,
    price: "64.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/19012/nendoroid-1151-magical-mirai-2018-miku-exclusive20210909-17706-x2gxja.png",
      profile: {
        url: "/uploads/product/profile_picture/19012/profile_nendoroid-1151-magical-mirai-2018-miku-exclusive20210909-17706-x2gxja.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/19012/small_profile_nendoroid-1151-magical-mirai-2018-miku-exclusive20210909-17706-x2gxja.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/19012/preview_nendoroid-1151-magical-mirai-2018-miku-exclusive20210909-17706-x2gxja.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/19012/thumb_nendoroid-1151-magical-mirai-2018-miku-exclusive20210909-17706-x2gxja.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/19012/cart_nendoroid-1151-magical-mirai-2018-miku-exclusive20210909-17706-x2gxja.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-1151-hatsune-miku-magical-mirai-2018-ver-19012-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 1151 Hatsune Miku Magical Mirai 2018 ver. ",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "59.9",
    base_price_required: null,
    gross_selling_price: "59.9",
    href: "/de/produkte/nendoroid-556b-mega-man-metal-blade-ver-7727-de",
    id: 7727,
    orderable: true,
    price: "59.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/7727/nendoroid-556b-mega-man-metal-blade-ver20210913-13394-vk6lmy.png",
      profile: {
        url: "/uploads/product/profile_picture/7727/profile_nendoroid-556b-mega-man-metal-blade-ver20210913-13394-vk6lmy.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/7727/small_profile_nendoroid-556b-mega-man-metal-blade-ver20210913-13394-vk6lmy.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/7727/small_profile.nendoroid-556b-mega-man-metal-blade-ver.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/7727/small_profile.nendoroid-556b-mega-man-metal-blade-ver.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/7727/small_profile.nendoroid-556b-mega-man-metal-blade-ver.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/7727/preview_nendoroid-556b-mega-man-metal-blade-ver20210913-13394-vk6lmy.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/7727/preview.nendoroid-556b-mega-man-metal-blade-ver.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/7727/preview.nendoroid-556b-mega-man-metal-blade-ver.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/7727/preview.nendoroid-556b-mega-man-metal-blade-ver.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/7727/thumb_nendoroid-556b-mega-man-metal-blade-ver20210913-13394-vk6lmy.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/7727/thumb.nendoroid-556b-mega-man-metal-blade-ver.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/7727/thumb.nendoroid-556b-mega-man-metal-blade-ver.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/7727/thumb.nendoroid-556b-mega-man-metal-blade-ver.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/7727/cart_nendoroid-556b-mega-man-metal-blade-ver20210913-13394-vk6lmy.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/7727/cart.nendoroid-556b-mega-man-metal-blade-ver.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/7727/cart.nendoroid-556b-mega-man-metal-blade-ver.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/7727/cart.nendoroid-556b-mega-man-metal-blade-ver.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-556b-mega-man-metal-blade-ver-7727-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 556b Mega Man Metal Blade Ver. ",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "59.9",
    base_price_required: null,
    gross_selling_price: "59.9",
    href: "/de/produkte/nendoroid-1251-natsuki-subaru-25935-de",
    id: 25935,
    orderable: true,
    price: "59.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/25935/nedoroid-1251-subaru-natsuki20210909-17706-1kag1e0.png",
      profile: {
        url: "/uploads/product/profile_picture/25935/profile_nedoroid-1251-subaru-natsuki20210909-17706-1kag1e0.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/25935/small_profile_nedoroid-1251-subaru-natsuki20210909-17706-1kag1e0.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/25935/small_profile.nendoroid-1251-natsuki-subaru.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/25935/small_profile.nendoroid-1251-natsuki-subaru.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/25935/small_profile.nendoroid-1251-natsuki-subaru.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/25935/preview_nedoroid-1251-subaru-natsuki20210909-17706-1kag1e0.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/25935/preview.nendoroid-1251-natsuki-subaru.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/25935/preview.nendoroid-1251-natsuki-subaru.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/25935/preview.nendoroid-1251-natsuki-subaru.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/25935/thumb_nedoroid-1251-subaru-natsuki20210909-17706-1kag1e0.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/25935/thumb.nendoroid-1251-natsuki-subaru.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/25935/thumb.nendoroid-1251-natsuki-subaru.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/25935/thumb.nendoroid-1251-natsuki-subaru.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/25935/cart_nedoroid-1251-subaru-natsuki20210909-17706-1kag1e0.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/25935/cart.nendoroid-1251-natsuki-subaru.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/25935/cart.nendoroid-1251-natsuki-subaru.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/25935/cart.nendoroid-1251-natsuki-subaru.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-1251-natsuki-subaru-25935-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid 1251 Natsuki Subaru ",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "10.9",
    base_price_required: null,
    gross_selling_price: "10.9",
    href: "/de/produkte/graue-katze-american-shorthair-face-parts-case-nendoroid-more-10276-de",
    id: 10276,
    orderable: true,
    price: "10.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/10276/graue-katze-american-shorthair-face-parts-case-nendoroid-more-exclusive20171113-27651-1htpgjg",
      profile: {
        url: "/uploads/product/profile_picture/10276/profile_graue-katze-american-shorthair-face-parts-case-nendoroid-more-exclusive20171113-27651-1htpgjg",
        type: "",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/10276/small_profile_graue-katze-american-shorthair-face-parts-case-nendoroid-more-exclusive20171113-27651-1htpgjg",
        type: "",
      },
      preview: {
        url: "/uploads/product/profile_picture/10276/preview_graue-katze-american-shorthair-face-parts-case-nendoroid-more-exclusive20171113-27651-1htpgjg",
        type: "",
      },
      thumb: {
        url: "/uploads/product/profile_picture/10276/thumb_graue-katze-american-shorthair-face-parts-case-nendoroid-more-exclusive20171113-27651-1htpgjg",
        type: "",
      },
      cart: {
        url: "/uploads/product/profile_picture/10276/cart_graue-katze-american-shorthair-face-parts-case-nendoroid-more-exclusive20171113-27651-1htpgjg",
        type: "",
      },
    },
    release_date: null,
    reservable: false,
    reservation: null,
    slug: "graue-katze-american-shorthair-face-parts-case-nendoroid-more-10276-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title:
      "Graue Katze - American Shorthair - Face Parts Case - Nendoroid More",
    waitlistable: false,
    weight: 100.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "54.9",
    base_price_required: null,
    gross_selling_price: "54.9",
    href: "/de/produkte/figma-ex-047b-bride-noir-ver-24338-de",
    id: 24338,
    orderable: true,
    price: "54.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/24338/figma-bride-noir-ver20210909-17748-utnrj.png",
      profile: {
        url: "/uploads/product/profile_picture/24338/profile_figma-bride-noir-ver20210909-17748-utnrj.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/24338/small_profile_figma-bride-noir-ver20210909-17748-utnrj.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/24338/small_profile.figma-ex-047b-bride-noir-ver.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/24338/small_profile.figma-ex-047b-bride-noir-ver.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/24338/small_profile.figma-ex-047b-bride-noir-ver.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/24338/preview_figma-bride-noir-ver20210909-17748-utnrj.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/24338/preview.figma-ex-047b-bride-noir-ver.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/24338/preview.figma-ex-047b-bride-noir-ver.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/24338/preview.figma-ex-047b-bride-noir-ver.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/24338/thumb_figma-bride-noir-ver20210909-17748-utnrj.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/24338/thumb.figma-ex-047b-bride-noir-ver.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/24338/thumb.figma-ex-047b-bride-noir-ver.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/24338/thumb.figma-ex-047b-bride-noir-ver.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/24338/cart_figma-bride-noir-ver20210909-17748-utnrj.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/24338/cart.figma-ex-047b-bride-noir-ver.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/24338/cart.figma-ex-047b-bride-noir-ver.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/24338/cart.figma-ex-047b-bride-noir-ver.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-047b-bride-noir-ver-24338-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-047b Bride Noir ver. ",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/figma-ex-047-bride-23512-de",
    id: 23512,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/23512/figma-ex-047-bride20210909-17706-1qahyst.png",
      profile: {
        url: "/uploads/product/profile_picture/23512/profile_figma-ex-047-bride20210909-17706-1qahyst.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/23512/small_profile_figma-ex-047-bride20210909-17706-1qahyst.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/23512/preview_figma-ex-047-bride20210909-17706-1qahyst.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/23512/thumb_figma-ex-047-bride20210909-17706-1qahyst.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/23512/cart_figma-ex-047-bride20210909-17706-1qahyst.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-047-bride-23512-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-047 Bride ",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "49.9",
    base_price_required: null,
    gross_selling_price: "49.9",
    href: "/de/produkte/figma-ex-046-groom-24339-de",
    id: 24339,
    orderable: true,
    price: "49.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/24339/figma-bridegroom20210909-17706-1r9bcc4.png",
      profile: {
        url: "/uploads/product/profile_picture/24339/profile_figma-bridegroom20210909-17706-1r9bcc4.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/24339/small_profile_figma-bridegroom20210909-17706-1r9bcc4.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/24339/small_profile.figma-ex-046-groom.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/24339/small_profile.figma-ex-046-groom.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/24339/small_profile.figma-ex-046-groom.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/24339/preview_figma-bridegroom20210909-17706-1r9bcc4.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/24339/preview.figma-ex-046-groom.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/24339/preview.figma-ex-046-groom.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/24339/preview.figma-ex-046-groom.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/24339/thumb_figma-bridegroom20210909-17706-1r9bcc4.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/24339/thumb.figma-ex-046-groom.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/24339/thumb.figma-ex-046-groom.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/24339/thumb.figma-ex-046-groom.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/24339/cart_figma-bridegroom20210909-17706-1r9bcc4.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/24339/cart.figma-ex-046-groom.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/24339/cart.figma-ex-046-groom.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/24339/cart.figma-ex-046-groom.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-046-groom-24339-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-046 Groom ",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "54.9",
    base_price_required: null,
    gross_selling_price: "54.9",
    href: "/de/produkte/figma-ex-046b-groom-noir-ver-24340-de",
    id: 24340,
    orderable: true,
    price: "54.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/24340/figma-bridegroom-noir-ver20210909-17748-uvljmg.png",
      profile: {
        url: "/uploads/product/profile_picture/24340/profile_figma-bridegroom-noir-ver20210909-17748-uvljmg.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/24340/small_profile_figma-bridegroom-noir-ver20210909-17748-uvljmg.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/24340/small_profile.figma-ex-046b-groom-noir-ver.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/24340/small_profile.figma-ex-046b-groom-noir-ver.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/24340/small_profile.figma-ex-046b-groom-noir-ver.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/24340/preview_figma-bridegroom-noir-ver20210909-17748-uvljmg.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/24340/preview.figma-ex-046b-groom-noir-ver.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/24340/preview.figma-ex-046b-groom-noir-ver.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/24340/preview.figma-ex-046b-groom-noir-ver.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/24340/thumb_figma-bridegroom-noir-ver20210909-17748-uvljmg.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/24340/thumb.figma-ex-046b-groom-noir-ver.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/24340/thumb.figma-ex-046b-groom-noir-ver.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/24340/thumb.figma-ex-046b-groom-noir-ver.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/24340/cart_figma-bridegroom-noir-ver20210909-17748-uvljmg.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/24340/cart.figma-ex-046b-groom-noir-ver.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/24340/cart.figma-ex-046b-groom-noir-ver.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/24340/cart.figma-ex-046b-groom-noir-ver.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-046b-groom-noir-ver-24340-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-046b Groom Noir ver. ",
    waitlistable: false,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "10.9",
    base_price_required: null,
    gross_selling_price: "10.9",
    href: "/de/produkte/panda-face-parts-case-nendoroid-more-10275-de",
    id: 10275,
    orderable: true,
    price: "10.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/10275/panda-face-parts-case-nendoroid-more-exclusive20171113-27651-yzoks8",
      profile: {
        url: "/uploads/product/profile_picture/10275/profile_panda-face-parts-case-nendoroid-more-exclusive20171113-27651-yzoks8",
        type: "",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/10275/small_profile_panda-face-parts-case-nendoroid-more-exclusive20171113-27651-yzoks8",
        type: "",
      },
      preview: {
        url: "/uploads/product/profile_picture/10275/preview_panda-face-parts-case-nendoroid-more-exclusive20171113-27651-yzoks8",
        type: "",
      },
      thumb: {
        url: "/uploads/product/profile_picture/10275/thumb_panda-face-parts-case-nendoroid-more-exclusive20171113-27651-yzoks8",
        type: "",
      },
      cart: {
        url: "/uploads/product/profile_picture/10275/cart_panda-face-parts-case-nendoroid-more-exclusive20171113-27651-yzoks8",
        type: "",
      },
    },
    release_date: null,
    reservable: false,
    reservation: null,
    slug: "panda-face-parts-case-nendoroid-more-10275-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Panda - Face Parts Case - Nendoroid More",
    waitlistable: false,
    weight: 100.0,
  },
  {
    availability: {
      description: "Keine mehr verfügbar",
      out: true,
      low: false,
      color: "",
    },
    base_price: "54.9",
    base_price_required: null,
    gross_selling_price: "54.9",
    href: "/de/produkte/figma-ex-025-saber-altria-pendragon-dress-ver-11375-de",
    id: 11375,
    orderable: false,
    price: "54.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/11375/figma-ex-025-saber-altria-pendragon-dress-ver20210913-15421-uxau36.png",
      profile: {
        url: "/uploads/product/profile_picture/11375/profile_figma-ex-025-saber-altria-pendragon-dress-ver20210913-15421-uxau36.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/11375/small_profile_figma-ex-025-saber-altria-pendragon-dress-ver20210913-15421-uxau36.png",
        type: "image/png",
      },
      preview: {
        url: "/uploads/product/profile_picture/11375/preview_figma-ex-025-saber-altria-pendragon-dress-ver20210913-15421-uxau36.png",
        type: "image/png",
      },
      thumb: {
        url: "/uploads/product/profile_picture/11375/thumb_figma-ex-025-saber-altria-pendragon-dress-ver20210913-15421-uxau36.png",
        type: "image/png",
      },
      cart: {
        url: "/uploads/product/profile_picture/11375/cart_figma-ex-025-saber-altria-pendragon-dress-ver20210913-15421-uxau36.png",
        type: "image/png",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-025-saber-altria-pendragon-dress-ver-11375-de",
    stock_state: "sold_out",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-025 Saber Altria Pendragon Dress Ver.",
    waitlistable: true,
    weight: 500.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "29.9",
    base_price_required: null,
    gross_selling_price: "29.9",
    href: "/de/produkte/nendoroid-more-alex-louis-armstrong-26004-de",
    id: 26004,
    orderable: true,
    price: "29.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/26004/nendoroid-more-alex-louis-armstrong20210909-17706-zf3iym.png",
      profile: {
        url: "/uploads/product/profile_picture/26004/profile_nendoroid-more-alex-louis-armstrong20210909-17706-zf3iym.png",
        type: "image/png",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/26004/small_profile_nendoroid-more-alex-louis-armstrong20210909-17706-zf3iym.png",
        type: "image/png",
      },
      small_profile_jp2: {
        url: "/uploads/product/profile_picture/26004/small_profile.nendoroid-more-alex-louis-armstrong.jp2",
        type: "image/jp2",
      },
      small_profile_jpx: {
        url: "/uploads/product/profile_picture/26004/small_profile.nendoroid-more-alex-louis-armstrong.jpx",
        type: "image/jpx",
      },
      small_profile_webp: {
        url: "/uploads/product/profile_picture/26004/small_profile.nendoroid-more-alex-louis-armstrong.webp",
        type: "image/webp",
      },
      preview: {
        url: "/uploads/product/profile_picture/26004/preview_nendoroid-more-alex-louis-armstrong20210909-17706-zf3iym.png",
        type: "image/png",
      },
      preview_jp2: {
        url: "/uploads/product/profile_picture/26004/preview.nendoroid-more-alex-louis-armstrong.jp2",
        type: "image/jp2",
      },
      preview_jpx: {
        url: "/uploads/product/profile_picture/26004/preview.nendoroid-more-alex-louis-armstrong.jpx",
        type: "image/jpx",
      },
      preview_webp: {
        url: "/uploads/product/profile_picture/26004/preview.nendoroid-more-alex-louis-armstrong.webp",
        type: "image/webp",
      },
      thumb: {
        url: "/uploads/product/profile_picture/26004/thumb_nendoroid-more-alex-louis-armstrong20210909-17706-zf3iym.png",
        type: "image/png",
      },
      thumb_jp2: {
        url: "/uploads/product/profile_picture/26004/thumb.nendoroid-more-alex-louis-armstrong.jp2",
        type: "image/jp2",
      },
      thumb_jpx: {
        url: "/uploads/product/profile_picture/26004/thumb.nendoroid-more-alex-louis-armstrong.jpx",
        type: "image/jpx",
      },
      thumb_webp: {
        url: "/uploads/product/profile_picture/26004/thumb.nendoroid-more-alex-louis-armstrong.webp",
        type: "image/webp",
      },
      cart: {
        url: "/uploads/product/profile_picture/26004/cart_nendoroid-more-alex-louis-armstrong20210909-17706-zf3iym.png",
        type: "image/png",
      },
      cart_jp2: {
        url: "/uploads/product/profile_picture/26004/cart.nendoroid-more-alex-louis-armstrong.jp2",
        type: "image/jp2",
      },
      cart_jpx: {
        url: "/uploads/product/profile_picture/26004/cart.nendoroid-more-alex-louis-armstrong.jpx",
        type: "image/jpx",
      },
      cart_webp: {
        url: "/uploads/product/profile_picture/26004/cart.nendoroid-more-alex-louis-armstrong.webp",
        type: "image/webp",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "nendoroid-more-alex-louis-armstrong-26004-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Nendoroid More Alex Louis Armstrong ",
    waitlistable: false,
    weight: 300.0,
  },
  {
    availability: {
      description: "In ausreichender Anzahl verfügbar",
      out: false,
      low: false,
      color: "",
    },
    base_price: "59.9",
    base_price_required: null,
    gross_selling_price: "59.9",
    href: "/de/produkte/figma-ex-045-hatsune-miku-crane-priestess-ver-snow-miku-2018-11073-de",
    id: 11073,
    orderable: true,
    price: "59.9",
    profile_picture: {
      url: "/uploads/product/profile_picture/11073/figma-ex-045-snow-miku-2017-crane-priestess-exklusiv20180305-17805-19zif16",
      profile: {
        url: "/uploads/product/profile_picture/11073/profile_figma-ex-045-snow-miku-2017-crane-priestess-exklusiv20180305-17805-19zif16",
        type: "",
      },
      small_profile: {
        url: "/uploads/product/profile_picture/11073/small_profile_figma-ex-045-snow-miku-2017-crane-priestess-exklusiv20180305-17805-19zif16",
        type: "",
      },
      preview: {
        url: "/uploads/product/profile_picture/11073/preview_figma-ex-045-snow-miku-2017-crane-priestess-exklusiv20180305-17805-19zif16",
        type: "",
      },
      thumb: {
        url: "/uploads/product/profile_picture/11073/thumb_figma-ex-045-snow-miku-2017-crane-priestess-exklusiv20180305-17805-19zif16",
        type: "",
      },
      cart: {
        url: "/uploads/product/profile_picture/11073/cart_figma-ex-045-snow-miku-2017-crane-priestess-exklusiv20180305-17805-19zif16",
        type: "",
      },
    },
    release_date: {
      key: "2021-10-02",
      label: "Erwarteter Release",
      value: "Anfang Oktober 2021",
      outdated: false,
    },
    reservable: false,
    reservation: null,
    slug: "figma-ex-045-hatsune-miku-crane-priestess-ver-snow-miku-2018-11073-de",
    stock_state: "stocked",
    tax_class: {
      key: "tax_class_standard",
      rate: 0.19,
      description: "inkl 19% MwSt",
    },
    title: "Figma EX-045 Hatsune Miku Crane Priestess Ver. - Snow Miku 2018",
    waitlistable: false,
    weight: 500.0,
  },
];
