import { SimpleGrid, Image, Box, Flex, VStack, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import gsc_logo from "./gsc_logo.png";
import figuya_logo from "./figuya-logo.png";
import { motion } from "framer-motion";
import around_akiba_logo from "./logos/around-akiba.jpg";
import peppermint_logo from "./logos/peppermint.jpg";
import altraverse_logo from "./logos/altraverse.png";
import tokyopop_logo from "./logos/tokyopop.jpg";
import carlsen_logo from "./logos/carlsen.png";
import crunchyroll_logo from "./logos/crunchyroll.png";
import animoon_logo from "./logos/animoon.webp"
import egmont_logo from "./logos/egmont.png";
import dokomi_logo from "./logos/dokomi.png";
import animemesse_logo from "./logos/animemesse.png";

const HomePage = () => (
  <Box p={[0, 0, 2]}>
    <VStack
      rounded={[0, 0, "lg"]}
      p={2}
      bg="whiteAlpha.100"
      mb={16}
      spacing={4}
      fontSize="18px"
      alignItems="stretch"
    >
      <Heading>
        <small>Figuya präsentiert:</small>
        <br />
        Wonderful Hobby Life for you - Europa
      </Heading>
      <p>
        Exklusive Figuren, Gewinnpsiel und tolle Unterhaltung mit zahlreichen
        Gästen in ganzen neun Tagen Streaming! Das Figuya x Good Smile Streaming
        Event wird vom 02.10 - 10.10 stattfinden und bietet euch die Möglichkeit
        exklusive Figuren bei uns online auf Figuya.com aber auch bei uns im
        Store in Berlin Mitte zu ergattern.
      </p>
      <p>
        Während das WonHobby Event bereits das 34. Mal in Japan stattfindet,
        wird das WonHobby Europe Event zum ersten Mal seine Premiere feiern und
        so eine neue Möglichkeit eröffnen, Menschen aus verschiedenen Ländern
        anzusprechen und zu begeistern.
      </p>
      <p>
        Mit dabei sein werden zahlreiche Gäste mit denen wir uns über die
        verschiedensten Themen zu Figuren und Anime austauschen werden und auch
        Gäste, live aus Japan, werden euch einen exklusiven Einblick in die Welt
        der Figuren geben.
      </p>
      <p>
        Ihr könnt die Figuren dann direkt zum Event bei uns bestellen und kaufen
        und sie werden zollfrei an euch gesendet. Euch erwartet also jede Menge
        an großartigen Neuigkeiten, Shopping und viel gute Unterhaltung.
      </p>

      <Flex p={2} bg="whiteAlpha.100" direction={["column", "column", "row"]}>
        <Box
          width={["100%", "100", "25%"]}
          p={4}
          borderBottomWidth={["4px", "4px", 0]}
          borderRightWidth={[0, 0, "4px"]}
        >
          Datum:
        </Box>
        <Box width={["100%", "100", "75%"]} p={4}>
          2-10.10.2021
        </Box>
      </Flex>

      <Flex p={2} bg="whiteAlpha.100" direction={["column", "column", "row"]}>
        <Box
          width={["100%", "100", "25%"]}
          p={4}
          borderBottomWidth={["4px", "4px", 0]}
          borderRightWidth={[0, 0, "4px"]}
        >
          Location:
        </Box>
        <Box width={["100%", "100", "75%"]} p={4}>
          <Link to="/">whl4u.eu</Link>
          <address>
            Figuya Store
            <br />
            Oranienburger Straße 13-14
            <br />
            10178 Berlin
            <br />
            Deutschland
          </address>
        </Box>
      </Flex>

      <p>
        Um die geltenden Corona Vorschriften in unserem Store zu beachten, gilt
        vor Ort eine beschränkung auf maximal 10 Personen, die sich zeitgleich
        im Store aufhalten können.
      </p>
    </VStack>

    <VStack
  mb={16}
      rounded={[0, 0, "lg"]}
      p={2}
      bg="whiteAlpha.100"
      spacing={4}
      fontSize="18px"
      alignItems="stretch"
    >
      <Heading>
  Wir bedanken uns herzlich bei allen Gästen und Teilnehmern!
      </Heading>
  <SimpleGrid spacing={2} columns={[1, 2, 4]}>
  <Box as="a" href="https://www.goodsmile.info" bg="white" p={2} rounded="md">
  <Image height="100px" src={gsc_logo} />
  </Box>
  <Box as="a" href="https://altraverse.de" bg="white" p={2} rounded="md">
  <Image height="100px" src={altraverse_logo} />
  </Box>
  <Box as="a" href="https://www.tokyopop.de" bg="white" p={2} rounded="md">
  <Image height="100px" src={tokyopop_logo} />
  </Box>
  <Box as="a" href="https://peppermint-anime.de" bg="white" p={2} rounded="md">
  <Image height="100px" src={peppermint_logo} />
  </Box>
  <Box as="a" href="https://www.carlsen.de/manga" bg="white" p={2} rounded="md">
  <Image height="100px" src={carlsen_logo} />
  </Box>
  <Box as="a" href="https://aroundakiba.tv" bg="white" p={2} rounded="md">
  <Image height="100px" src={around_akiba_logo} />
  </Box>
  <Box as="a" href="https://www.crunchyroll.com" bg="white" p={2} rounded="md">
  <Image height="100px" src={crunchyroll_logo} />
  </Box>
  <Box as="a" href="https://www.egmont-manga.de" bg="white" p={2} rounded="md">
  <Image height="100px" src={egmont_logo} />
  </Box>
  <Box as="a" href="https://www.animoon-publishing.de/" bg="white" p={2} rounded="md">
  <Image height="100px" src={animoon_logo} />
  </Box>
  <Box as="a" href="https://www.dokomi.de/de" bg="white" p={2} rounded="md">
  <Image height="100px" src={dokomi_logo} />
  </Box>
  <Box as="a" href="https://www.animemesse.de" bg="white" p={2} rounded="md">
  <Image height="100px" src={animemesse_logo} />
  </Box>
  </SimpleGrid>

    </VStack>

    <VStack
      rounded={[0, 0, "lg"]}
      p={2}
      bg="whiteAlpha.100"
      spacing={4}
      fontSize="18px"
      alignItems="stretch"
    >
      <Heading>
        <small>Figuya presents:</small>
        <br />
        Wonderful Hobby Life for you - Europe
      </Heading>

      <p>
        Exclusive figures, giveaways, great entertainment with numerous guests
        and all of that in nine days of streaming! The Figuya x Good Smile
        streaming event will take place from 02.10 - 10.10 and offers you the
        opportunity to get exclusive figures online at Figuya.com but also at
        our store in Berlin, Germany.
      </p>
      <p>
        While the WonHobby Event already takes place for the 34th time in Japan,
        the WonHobby Europe Event will celebrate its premiere for the first time
        and this will open a new possibility to address and inspire people from
        different countries.
      </p>
      <p>
        There will be a lot of guests with whom we will talk about different
        topics such as figurines and anime and even guests from Japan will give
        you a little insight into the world of figurines.
      </p>
      <p>
        You can order and buy the figurines directly at the event and they will
        be sent to you duty free. So you can expect a lot of great news,
        shopping and a lot of good entertainment.
      </p>

      <Flex p={2} bg="whiteAlpha.100" direction={["column", "column", "row"]}>
        <Box width={["100%", "100", "25%"]} p={4} borderRightWidth="4px">
          Datum:
        </Box>
        <Box width={["100%", "100", "75%"]} p={4}>
          2-10.10.2021
        </Box>
      </Flex>

      <Flex p={2} bg="whiteAlpha.100" direction={["column", "column", "row"]}>
        <Box width={["100%", "100", "25%"]} p={4} borderRightWidth="4px">
          Location:
        </Box>
        <Box width={["100%", "100", "75%"]} p={4}>
          <Link to="/">whl4u.eu</Link>
          <address>
            Figuya Store
            <br />
            Oranienburger Straße 13-14
            <br />
            10178 Berlin
            <br />
            Deutschland
          </address>
        </Box>
      </Flex>
    </VStack>
  </Box>
);

export default HomePage;
